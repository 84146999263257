/**
 * Add all couriers related action types here
 */

// crud action types for couriers
export const GET_CURRENT_DEPARTMENT = "GET_CURRENT_DEPARTMENT";
export const GET_ALL_DEPARTMENT = "GET_ALL_DEPARTMENT";

export const SET_COURIER_LOCATION = "SET_COURIER_LOCATION";

export const SET_FEEDBACK = "SET_FEEDBACK";
export const SHOW_ERROR = "SHOW_ERROR";

export const UPDATE_IDLE_STATE = "UPDATE_IDLE_STATE";
