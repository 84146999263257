import * as apiService from "../index";

/**
 * get cargo type
 */
export const getCargoTypes = () => {
	let apiCall = "services/cargo-types";
	return apiService.get(apiCall);
};

/**
 * get courier vehicle type
 */
export const getCourierVehicleType = (payload: any) => {
	let apiCall = `services/transportation-vehicles?${payload}`;     // also got "data=" from parms      
	return apiService.get(apiCall);
};
