import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useSelector } from "react-redux";
import { IRootReducerState } from "../../../common/interface/store/reducer/Reducer";
import { confirmEmail } from 'store/apiService/order/orderServices';
import SrSpinner from 'component/Spinner/Spinner';
import { Alert } from 'reactstrap';
import { FormattedMessage } from 'react-intl';


function ConfirmEmail() {
    // Use useParams to get orderId from the URL path
    const { orderId } = useParams<any>();

    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<boolean>(false);
    const currentDepartment = useSelector(
		(state: IRootReducerState) => state.departmentReducer.currentDepartment
	);

    // Use useLocation to get the full location object
    const location = useLocation();

    const useQuery = () => new URLSearchParams(useLocation().search);
	const query = useQuery();
    const hash = query.get('hash'); // Get 'hash' from the query string

    useEffect(() => {
        if (orderId && hash) {
            // Perform the Axios PUT request
            confirmEmail({ id: orderId, hash: hash })
            .then(response => {})
            .catch(error => {
                setError(true);
            }).finally(() => {
                setLoading(false);
            });
        }
    }, [orderId, hash]); // Only re-run the effect if orderId or hash changes

    return (
        <div className='container' style={{marginTop: 25}}>
            {loading ? <SrSpinner/> : <Alert color={error ? 'danger' : 'success'}>
            <FormattedMessage
							id={error ? 'order.errorVerifyingEmail' : 'order.thanksForConfirmingEmail'}
                            values={
                                {
                                    orderNumber: orderId,
                                    departmentName: currentDepartment?.name,
                                }
                            }
						/>
                        </Alert> 
            }
        </div> 
    );
}

export default ConfirmEmail;
