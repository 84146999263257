import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Modal, ModalHeader, ModalBody, Row, Col } from "reactstrap";

import "./styles/FeedbackPopup.scss";
import ReactStars from "component/StarRating";
import FeedbackRow from "component/FeedbackRow";
import { IFeedbackPopupProps } from "./Interface/props/FeedbackPopupProps";
import { IRootReducerState } from "common/interface/store/reducer/Reducer";
import { SET_FEEDBACK } from "store/actionTypes/department/departmentActionTypes";

/**
 * Sample component for greeting
 * @param props
 * @constructor
 */
const FeedbackPopup: FC<IFeedbackPopupProps> = props => {
	const dispatch = useDispatch();
	// destructure all props here
	const { show, toggle, rating, starRating, total } = props;
	/**
	 * Get Feedback
	 */
	const feedback = useSelector(
		(state: IRootReducerState) => state.departmentReducer.feedback
	);

	const onClose = () => {
		dispatch({ type: SET_FEEDBACK, payload: [] });
		toggle();
	};

	return (
		<Modal isOpen={show} toggle={onClose} className="feedback-modal">
			<ModalHeader
				toggle={onClose}
				className="modalHeader"
				data-cy="Feedbackpopup-Header"
			>
				<Row className="review">
					<Col className="review-wrap" xs="3">
						<p className="reviewstate">{rating}</p>
					</Col>
					<Col className="review-create">
						<p className="">
							Gemiddeld uit {total}{" "}
							<FormattedMessage id="header.review" defaultMessage="Review" />
						</p>
						<ReactStars
							className="startrating"
							valueProp={starRating}
							count={5}
							edit={false}
							half={true}
							size={30}
							color2={"#faca30"}
						/>
					</Col>
				</Row>
			</ModalHeader>
			<ModalBody className="scrollData" data-cy="Feedbackpopup-Body">
				{feedback.map((item, i) => (
					<FeedbackRow item={item} key={`feedback${i}`} />
				))}
			</ModalBody>
		</Modal>
	);
};

export default FeedbackPopup;
