import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	Button,
	Col,
	Row,
	Card,
	CardImg,
	CardBody,
	CardTitle,
	Spinner,
	CardFooter,
	Alert
} from "reactstrap";
import { FormattedMessage } from "react-intl";
import moment from "moment";
import "moment/locale/nl";

import "./Styles/courierVehicleStyle.scss";
import arrow from "common/images/home-curve-arrow.svg";
import { ICourierVehicleTypeProps } from "./Interface/props/ICourierVehicleTypeProps";
import { ICourierVehicle } from "store/interface/shipment/Shipment";
import { IRootReducerState } from "common/interface/store/reducer/Reducer";
import { setSelectedCourierVehicle } from "store/actions/shipment/shipmentActions";
import { getVehicleServicesAction } from "store/actions/vehicles/vehiclesActions";
import { useIsShowSupportPhone } from "util/customHooks";

const CourierVehicleType: React.FC<ICourierVehicleTypeProps> = props => {
	const dispatch = useDispatch();
	const submitBtnRef = useRef<any>();
	const isShowSupportPhone = useIsShowSupportPhone();
	const [haveRecommended, setHaveRecommended] = useState(false);
	const [selectCarrierError, setSelectCarrierError] = useState(false);
	const [vehicleError, setVehicleError] = useState(false);

	/**
	 * Get shipment state
	 */
	const shipmentReducerState = useSelector(
		(state: IRootReducerState) => state.shipmentReducer
	);

	/**
	 * Get shipment state
	 */
	const errorState = useSelector(
		(state: IRootReducerState) => state.shipmentReducer.error
	);

	/**
	 * Get phone number
	 */
	const supportPhone = useSelector(
		(state: IRootReducerState) =>
			state.departmentReducer.currentDepartment?.support_phone
	);

	/**
	 * Get last synced state
	 */
	const lastSyncState = useSelector(
		(state: IRootReducerState) => state.orderReducer.lastSyncedData
	);

	const [vehicleList, setVehicleList] = useState<ICourierVehicle[]>([]);
	const [transportation_time, setTransportation_time] = useState<number>(0);

	useEffect(() => {
		if (
			shipmentReducerState.courierVehicleList &&
			shipmentReducerState.transportation_time
		) {
			setVehicleList(shipmentReducerState.courierVehicleList);
			setTransportation_time(shipmentReducerState.transportation_time);
		}
	}, [
		shipmentReducerState.courierVehicleList,
		shipmentReducerState.transportation_time
	]);

	useEffect(() => {
		setHaveRecommended(
			!!vehicleList.filter(vehicle => vehicle.recommended).length
		);
		if (vehicleList.length === 0) setVehicleError(true);
		if (vehicleList.length) setVehicleError(false);
	}, [vehicleList]);

	const onSelectVehicle = (id: number) => {
		setSelectCarrierError(false);
		dispatch(setSelectedCourierVehicle(id));
		if (window.innerWidth <= 425) {
			setTimeout(() => {
				submitBtnRef.current.scrollIntoView({
					behavior: "smooth",
					inline: "end",
					block: "end"
				});
			}, 200);
		}
	};

	const getSelectedVehicleCount = () => {
		return vehicleList.filter(vehicle => vehicle.checked).length;
	};

	const onSubmit = () => {
		if (getSelectedVehicleCount() === 0) {
			setSelectCarrierError(true);
		}
		if (getSelectedVehicleCount() >= 1) {
			setSelectCarrierError(false);
			const { id } = vehicleList.filter(vehicle => vehicle.checked)[0];
			const Data = {
				...lastSyncState,
				transportation_vehicle_id: id
			};
			dispatch(getVehicleServicesAction(Data));
			props.onFinish();
		}
	};

	const onClickChat = () => {
		// @ts-ignore
		Freshbots.showWidget("open");
	};

	const renderVehicleList = vehicleList.length ? (
		vehicleList.map(vehicle => {
			return (
				<Col
					key={vehicle.id}
					md={{ size: 4 }}
					className="single-vehicle-wrap"
					data-cy={`vehicleCard${vehicle.id}`}
				>
					<Card
						data-cy={`vehicleCardHead${vehicle.id}`}
						onClick={() => onSelectVehicle(vehicle.id)}
						className={vehicle.checked ? "cardSelected" : ""}
					>
						<CardImg
							data-cy={`vehicleCardImage${vehicle.id}`}
							top
							width="100%"
							height="100%"
							src={vehicle.image}
							className="cardImg"
							alt="Card image cap"
						/>
						<CardBody data-cy={`vehicleCardBody${vehicle.id}`}>
							<CardTitle
								data-cy={`vehicleCardTitle${vehicle.id}`}
								className="font-weight-bold cardTitle"
							>
								{vehicle.name}
							</CardTitle>
							{/* <CardText data-cy={`vehicleCardText${vehicle.id}`}>
								{vehicle.recommended && (
									<span className="recommended-flag">Recommended</span>
								)}
							</CardText> */}
						</CardBody>
						<CardFooter className="cardFooter">
							<span>Prijs per km: {vehicle.price_per_km / 100}</span>
						</CardFooter>
					</Card>
				</Col>
			);
		})
	) : (
		<Row>
			{!errorState && !vehicleError && (
				<Col sm="12" md={{ size: 2, offset: 5 }}>
					<Spinner style={{ width: "3rem", height: "3rem" }} color="success" />
				</Col>
			)}
			<Col sm="12">
				{vehicleError && errorState ? (
					<Alert color="danger" className="vehicle-error-wrap">
						<FormattedMessage
							id="shipment.vehicleError"
							defaultMessage="Select Courier Vehicle"
						/>
						<ul>
							{isShowSupportPhone && (
								<li>
									<a href={`tel:${supportPhone}`}>{supportPhone}</a>
								</li>
							)}
							<li>
								<span onClick={onClickChat}>
									<FormattedMessage id="shipment.chat" />
								</span>
							</li>
						</ul>
					</Alert>
				) : (
					<Col sm="12" md={{ size: 2, offset: 5 }}>
						<Spinner
							style={{ width: "3rem", height: "3rem" }}
							color="success"
						/>
					</Col>
				)}
			</Col>
		</Row>
	);

	const renderTime =
		transportation_time &&
		moment
			.duration(transportation_time, "seconds")
			.locale("nl")
			.humanize();

	return (
		<Row data-cy="SelecteerCourierVehicle">
			<Col
				className="shipment-details-wrap shipment-vehicles-wrap"
				md={{ size: 10, offset: 2 }}
			>
				<Row>
					<Col md={12}>
						<h2 data-cy="pageTitle">
							<FormattedMessage
								id="shipment.CourierVehicle"
								defaultMessage="Select Courier Vehicle"
							/>
						</h2>
					</Col>
				</Row>
				<Row
					className={
						haveRecommended
							? "stepperContainer addMarginTop"
							: "stepperContainer"
					}
				>
					{renderVehicleList}
				</Row>
				{haveRecommended && (
					<div className="recommended-wrap">
						<span>Aanbevolen vervoer</span>
						<img alt="arrow" src={arrow} className="recommended-arrow " />
					</div>
				)}
			</Col>
			<Col md={{ size: 10, offset: 2 }} className="clearfix">
				<span className="float-right" data-cy="transportId">
					<i style={{ marginRight: "5px" }} className="fas fa-clock"></i>
					Transporttijd: {renderTime}
				</span>
			</Col>
			{selectCarrierError ? (
				<Col
					md={5}
					className="justify-content-center align-items-center selectCourierError"
				>
					<Alert
						color="danger"
						className="vehicle-error-wrap justify-content-center align-items-center"
					>
						<FormattedMessage
							id="shipment.carrierTypeError"
							defaultMessage="Select Courier Type"
						/>
					</Alert>
				</Col>
			) : (
				""
			)}
			<Col
				style={{ display: "flex" }}
				className="action-btns-wrap"
				md={{ size: 10, offset: 2 }}
			>
				<Col className="back-btn-col" md={{ size: 3 }}>
					<Button
						className="back-btn"
						onClick={event => props.onChangeStep(1)}
						data-cy="screen3-backButton"
					>
						<FormattedMessage
							id="shipment.shipmentModalBack"
							defaultMessage="Back"
						/>
					</Button>
				</Col>
				{!errorState && (
					<Col md={{ size: 3 }}>
						<span ref={submitBtnRef}>
							<Button
								data-cy="screen3-finishButton"
								className="btn-sr-yellow"
								onClick={onSubmit}
								//disabled={!getSelectedVehicleCount()}
							>
								<FormattedMessage
									id="shipment.shipmentModalFinish"
									defaultMessage="Back"
								/>
							</Button>
						</span>
					</Col>
				)}
			</Col>
		</Row>
	);
};

export default CourierVehicleType;
