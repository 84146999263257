import React, { FC } from "react";
import Routes from "./../router/Routes";
import { useDispatch, useSelector } from "react-redux";
import { IntlProvider } from "react-intl";
import AppLocale from "../lngProvider";

import { IRootReducerState } from "common/interface/store/reducer/Reducer";
// import { useIdleTimer } from "react-idle-timer";
// import { UPDATE_IDLE_STATE } from "store/actionTypes/department/departmentActionTypes";

const App: FC = () => {
	const dispatch = useDispatch();
	const locale: string = useSelector(
		(state: IRootReducerState) =>
			(state.departmentReducer.currentDepartment &&
				state.departmentReducer.currentDepartment.config.default_locale) ||
			"nl_NL"
	);
	const isUserIdle: boolean = useSelector(
		(state: IRootReducerState) => state.departmentReducer.isUserIdle
	);

	const lang = locale.split("_")[0];

	// const handleOnIdle = () => {
	// 	dispatch({ type: UPDATE_IDLE_STATE, payload: true });
	// };

	// const handleOnAction = () => {
	// 	if (isUserIdle) dispatch({ type: UPDATE_IDLE_STATE, payload: false });
	// };

	// useIdleTimer({
	// 	timeout: 1000 * 60,
	// 	onIdle: handleOnIdle,
	// 	onAction: handleOnAction,
	// 	debounce: 500
	// });

	return (
		// @ts-ignore
		<IntlProvider locale={lang} messages={AppLocale[lang].messages}>
			<Routes />
		</IntlProvider>
	);
};

export default App;
