import { IRootReducerState } from "common/interface/store/reducer/Reducer";
import { useSelector } from "react-redux";

export const useIsShowSupportPhone = () => {
	const currentDepartment = useSelector(
		(state: IRootReducerState) => state.departmentReducer.currentDepartment
	);
	if (currentDepartment?.config.support_phone_enabled === false) {
		return false;
	}
	return true;
};
