import React, { FC } from "react";
import { Route, Redirect, RouteComponentProps } from "react-router-dom";
import { IExtendedRouteProps } from "./interface/Routes";
import { useSelector } from "react-redux";
import { IRootReducerState } from "common/interface/store/reducer/Reducer";

/**
 * Private route need auth before enter into this route else redirected on login
 * @param Component
 * @param rest
 * @constructor
 */
const HaveSelectedServicesRoute: FC<IExtendedRouteProps> = ({
	component: Component,
	syncedSelectedServices,
	...rest
}) => {
	/**
	 * Get selected services
	 */
	const selectedServices = useSelector((state: IRootReducerState) => {
		return state.orderReducer.selectedServices;
	});

	return (
		<Route
			{...rest}
			render={(props: RouteComponentProps) =>
				selectedServices.length > 0 ? (
					<Component {...props} />
				) : (
					<Redirect to="/calculate" />
				)
			}
		/>
	);
};

export default React.memo(HaveSelectedServicesRoute);
