import React, { useState, useEffect } from "react";
import { Row, Container, Alert, Spinner, Button } from "reactstrap";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { useParams, useHistory } from "react-router-dom";

import { payOrderAction } from "store/actions/department/deparmentActions";

const stripePromise = loadStripe(
	process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY as string
);
const PayOrder = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	let { orderId } = useParams<any>();
	const useQuery = () => new URLSearchParams(useLocation().search);
	const query = useQuery();
	const [loading, setLoading] = useState<boolean>(true);
	const [paymentStatus, setPaymentStatus] = useState<string>("");
	const [error, setError] = useState<string>("");

	useEffect(() => {
		const hash = query.get("secret_hash");
		const status = query.get("failed");
		if (hash && orderId && !status) {
			initPay();
		} else if (!hash || !orderId) {
			setError("De opgevraagde factuur is niet gevonden");
			setLoading(false);
		}
	}, [orderId]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const status = query.get("failed");
		if (status) {
			setPaymentStatus(status);
			setLoading(false);
		}
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const initPay = () => {
		const hash = query.get("secret_hash");
		if (hash && orderId)
			dispatch(
				payOrderAction(orderId, hash, (status, res) => {
					if (status) {
						const handleClick = async (id: string) => {
							// Get Stripe.js instance
							const stripe = await stripePromise;
							const result = await stripe?.redirectToCheckout({
								sessionId: id
							});
							setLoading(false);

							if (result && result.error && result.error.message) {
								setError(result.error.message);
							}
						};
						handleClick(res.id);
					} else {
						setLoading(false);
						if (res.response) {
							if (res.response.status === 404) {
								setError("De opgevraagde factuur is niet gevonden");
							} else if (res.response.status === 403) {
								setError("Geen toegang");
							} else {
								setError(res.response.data.message);
							}
						} else {
							setError("Er is iets fout gegaan.");
						}
					}
				})
			);
	};

	return (
		<div className="mt-5">
			<Container>
				<Row className="justify-content-center">
					{loading && <Spinner style={{ width: "3rem", height: "3rem" }} />}
					{error && (
						<Alert className="text-left w-100" color="danger">
							{error}
						</Alert>
					)}
					{paymentStatus === "true" && (
						<Alert className="text-left w-100" color="danger">
							<h1>Betaling mislukt!</h1>
							<p>Neem contact op met uw bank voor hulp!</p>
							<Button
								className="mr-2"
								onClick={() => {
									setPaymentStatus("");
									setLoading(true);
									initPay();
								}}
							>
								Probeer het opnieuw
							</Button>
							<Button
								onClick={() => {
									history.push("/calculate");
								}}
							>
								Plaats opnieuw een boeking
							</Button>
						</Alert>
					)}
				</Row>
			</Container>
		</div>
	);
};

export default PayOrder;
