import { ICourierState } from "../../interface/shipment/Shipment";

export const initialShipmentState: ICourierState = {
	/**
	 * List of couriers
	 */
	courierList: [],
	/**
	 * Current selected department
	 */
	selectedCourier: undefined,
	toDate: new Date(new Date().setDate(new Date().getDate() + 2)),
	toTime: "",
	fromDate: new Date(new Date().setDate(new Date().getDate() + 2)),
	fromTime: "",
	courierVehicleList: [],
	transportation_time: 0,
	error: false
};
