import React from "react";
import moment from "moment";
import { Row, Col } from "reactstrap";
import ReactStars from "component/StarRating";

import "./styles/FeedbackRow.scss";
import { IFeedbackData } from "store/interface/department/Department";

const FeedbackRow = ({ item }: { item: IFeedbackData }) => {
	return (
		<Row className="align-items-center">
			<Col xs="6" sm="8" mb="0" className="font-weight-bold">
				{item.name ? item.name : "NAME"}
			</Col>
			<Col xs="6" sm="4">
				<ReactStars
					className="startrating "
					valueProp={item.rating / 10}
					count={5}
					edit={false}
					size={23}
					color2={"#faca30"}
				/>
			</Col>
			<div className="w-100"> </div>
			<Col xs="12" className="review-text">
				<p>{item.content}</p>
				<span className="feedback-date">
					{moment(item.created_at).format("DD-MM-YYYY")}
				</span>
			</Col>
		</Row>
	);
};

export default FeedbackRow;
