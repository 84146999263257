import React, { FC, useState, useEffect } from "react";
import { Modal, ModalBody } from "reactstrap";
import { Carousel } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import "component/Modal/CouriersModal/styles/CouriersModal.scss";
import LocationInput from "../../LocationInput/LocationInput";
import CourierDimensionSelection from "../../CourierDimensionSelection/CourierDimensionSelection";
import CourierVehicleType from "../../CourierVehicleType/CourierVehicleType";
import { getCouriersAction } from "store/actions/shipment/shipmentActions";

const CouriersModal: FC<{
	isOpen: boolean;
	isManualOpen?: boolean;
	redirectAfterFirstPopup?: boolean;
	onChange: () => void;
	index: number;
}> = props => {
	const history = useHistory();
	const dispatch = useDispatch();
	/**
	 * Handle state for CouriersModal
	 */
	const [index, setIndex] = useState<number>(props.index);

	const handleSelect = (selectedIndex: number) => {
		if (props.redirectAfterFirstPopup) {
			const selectedLocationData = JSON.parse(
				localStorage.getItem("locationData") || ""
			);
			const fromPlaceId = selectedLocationData.locationsLatLng[0].placeId;
			const toPlaceId = selectedLocationData.locationsLatLng[1].placeId;
			history.push(`/calculate?from=${fromPlaceId}&to=${toPlaceId}`);
		} else {
			setIndex(selectedIndex);
		}
	};

	useEffect(() => {
		dispatch(getCouriersAction());
	}, [dispatch]);

	const ControlledCarousel = (
		<Carousel
			activeIndex={index}
			onSelect={handleSelect}
			controls={false}
			touch={false}
			interval={null}
			indicators={false}
			keyboard={true}
		>
			<Carousel.Item>
				<LocationInput onChangeStep={handleSelect} isOpen={props.isOpen} />
			</Carousel.Item>
			<Carousel.Item>
				<CourierDimensionSelection onChangeStep={handleSelect} />
			</Carousel.Item>
			<Carousel.Item>
				<CourierVehicleType
					onChangeStep={handleSelect}
					onFinish={props.onChange}
				/>
			</Carousel.Item>
		</Carousel>
	);

	return (
		<Modal
			className={`change-location-modal ${
				index === 1 ? "new-design-modal" : ""
			}`}
			size="lg"
			isOpen={props.isOpen}
		>
			<ModalBody>
				{ControlledCarousel}
				<img
					alt="PietKoeriers"
					className="bgImg"
					src="https://serviceright-koeriers.nl/wp-content/themes/serviceright-courier/assets/img/PietKoeriers.png"
				/>
			</ModalBody>
			{props.isManualOpen && (
				<span className="modal-close-icon" onClick={props.onChange}>
					X
				</span>
			)}
		</Modal>
	);
};

export default CouriersModal;
