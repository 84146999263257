import { IDepartmentState } from "../../interface/department/Department";

export const initialDepartmentState: IDepartmentState = {
	/**
	 * List of departments
	 */
	departments: [],
	/**
	 * Current selected couriers
	 */
	currentDepartment: undefined,
	/**
	 * location for correier
	 */
	location: {
		locationName: {
			fromInput: "",
			toInput: ""
		},
		locationsLatLng: [],
		distance: ""
	},
	/**
	 * Feedback
	 */
	feedback: [],
	error: false,
	isUserIdle: false
};
