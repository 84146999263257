/**
 * Add all shipment related action types here
 */

export const GET_COURIERS = "GET_COURIERS";
export const SET_COURIERS = "SET_COURIERS";
export const SET_FROM_DATE = "SET_FROM_DATE";
export const SET_FROM_TIME = "SET_FROM_TIME";
export const SET_TO_DATE = "SET_TO_DATE";
export const SET_TO_TIME = "SET_TO_TIME";
export const SET_SELECTED_COURIER_VEHICLE = "SET_SELECTED_COURIER_VEHICLE";
export const SET_COURIER_VEHICLE_LIST = "SET_COURIER_VEHICLE_LIST";
export const SET_COURIER_VEHICLE_LIST_FAIL = "SET_COURIER_VEHICLE_LIST_FAIL";
export const RESET_COURIER_VEHICLE_LIST = "RESET_COURIER_VEHICLE_LIST";
