import React, { useState, useEffect } from "react";
import { Row, Container, Alert, Spinner, Button } from "reactstrap";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";

import { payInvoiceAction } from "store/actions/department/deparmentActions";

const stripePromise = loadStripe(
	process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY as string
);
const PayInvoice = () => {
	const dispatch = useDispatch();
	const useQuery = () => new URLSearchParams(useLocation().search);
	const query = useQuery();
	const [loading, setLoading] = useState<boolean>(true);
	const [paymentStatus, setPaymentStatus] = useState<string>("");
	const [error, setError] = useState<string>("");

	useEffect(() => {
		const hash = query.get("secret_hash");
		const invoiceId = query.get("invoice_id");
		const status = query.get("status");
		if (hash && invoiceId && !status) {
			initPay();
		} else if (!hash || !invoiceId) {
			setError("De opgevraagde factuur is niet gevonden");
			setLoading(false);
		}
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const status = query.get("status");
		if (status) {
			setPaymentStatus(status);
			setLoading(false);
		}
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const initPay = () => {
		const hash = query.get("secret_hash");
		const invoiceId = query.get("invoice_id");
		if (hash && invoiceId)
			dispatch(
				payInvoiceAction(
					invoiceId,
					hash,
					{
						cancel_url: `${window.location.origin}/payment-link/invoices?invoice_id=${invoiceId}&secret_hash=${hash}&status=cancel`,
						success_url: `${window.location.origin}/payment-link/invoices?invoice_id=${invoiceId}&secret_hash=${hash}&status=success`
					},
					(status, res) => {
						if (status) {
							const handleClick = async (id: string) => {
								// Get Stripe.js instance
								const stripe = await stripePromise;
								setLoading(false);
								const result = await stripe?.redirectToCheckout({
									sessionId: id
								});

								if (result && result.error && result.error.message) {
									setError(result.error.message);
								}
							};
							handleClick(res.id);
						} else {
							setLoading(false);
							if (res.response) {
								if (res.response.status === 404) {
									setError("De opgevraagde factuur is niet gevonden");
								} else if (res.response.status === 403) {
									setError("Geen toegang");
								} else {
									setError(res.response.data.message);
								}
							} else {
								setError("Er is iets fout gegaan.");
							}
						}
					}
				)
			);
	};

	return (
		<div className="mt-5">
			<Container>
				<Row className="justify-content-center">
					{loading && <Spinner style={{ width: "3rem", height: "3rem" }} />}
					{error && (
						<Alert className="text-left w-100" color="danger">
							{error}
						</Alert>
					)}
					{paymentStatus === "cancel" && (
						<Alert className="text-left w-100" color="danger">
							<h1>Betaling mislukt!</h1>
							<p>Neem contact op met uw bank voor hulp!</p>
							<Button
								onClick={() => {
									setPaymentStatus("");
									setLoading(true);
									initPay();
								}}
							>
								Probeer het opnieuw
							</Button>
						</Alert>
					)}
					{paymentStatus === "success" && (
						<Alert className="text-left w-100" color="success">
							<h1>Bedankt voor je bestelling!</h1>
							<p>We waarderen uw bedrijf!</p>
						</Alert>
					)}
				</Row>
			</Container>
		</div>
	);
};

export default PayInvoice;
