import React, { FC, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Helmet } from "react-helmet";
import { Row, Col, Button } from "reactstrap";
import { useSelector } from "react-redux";

import "./styles/PageNotFound.scss";
import VehiclesModal from "component/Modal/VehiclesModal/VehiclesModal";
import { IRootReducerState } from "common/interface/store/reducer/Reducer";
import CouriersModal from "component/Modal/CouriersModal/CouriersModal";

const PageNotFound: FC = () => {
	const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
	/**
	 * get current department form redux
	 */
	let currentDepartment = useSelector(
		(state: IRootReducerState) => state.departmentReducer.currentDepartment
	);

	const toggle = () => {
		setIsOpenModal(!isOpenModal);
	};

	return (
		<>
			<FormattedMessage id="app.404-error" defaultMessage="404 Page Not found">
				{title => (
					<Helmet>
						<title>{title}</title>
					</Helmet>
				)}
			</FormattedMessage>

			<Row className="error-page-wrap container">
				<Col className="left-side" md="8">
					<h1>
						{currentDepartment?.slug === "vehicles"
							? "404 - Oops you crashed it!"
							: "404 - Oops uw pakket kan niet worden geleverd!"}
					</h1>
					<p>
						De pagina die u probeerd te bezoeken bestaat niet (meer).
						<br />
						Mocht u nog vragen hebben, dan kunt u gerust contact opnemen:{" "}
						<a href="tel:0882222000" className="font-weight-bold">
							088 2222 000
						</a>
					</p>
					<Button className="btn-sr-yellow" onClick={toggle}>
						BEKIJK &amp; BOEK
					</Button>
				</Col>
				<Col
					className={
						currentDepartment?.slug === "vehicles"
							? "right-side vehicles"
							: "right-side couriers"
					}
					md="4"
				></Col>
			</Row>
			{isOpenModal &&
				(currentDepartment?.slug === "vehicles" ? (
					<VehiclesModal
						onClickVehicleModal={toggle}
						queryParamVerify={false}
						redirectAfterFirstPopup={true}
						licensePlate={""}
					/>
				) : (
					<CouriersModal
						redirectAfterFirstPopup={true}
						isOpen={true}
						onChange={toggle}
						index={0}
					/>
				))}
		</>
	);
};

export default PageNotFound;
