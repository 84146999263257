import React, { useState, useEffect } from "react";
import { Row, Col, Modal, ModalHeader, ModalBody } from "reactstrap";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";

import "./styles/Reviews.scss";
import ReactStars from "component/StarRating";
import FeedbackRow from "component/FeedbackRow";
import { IRootReducerState } from "common/interface/store/reducer/Reducer";
import { getFeedBack } from "store/apiService/department/departmentServices";

const Reviews = ({
	currentDepartment,
	average,
	total
}: {
	currentDepartment: string;
	average: number;
	total: number;
}) => {
	const [isOpen, setIsOpen] = useState(false);
	const [feedback, setFeedback] = useState([]);

	/**
	 * Get vehicle data
	 */
	const vehicle = useSelector(
		(state: IRootReducerState) => state.vehiclesReducer.vehicle
	);

	useEffect(() => {
		if (currentDepartment === "vehicles" && vehicle) {
			getFeedBack(prepareData(), 3).then(res => {
				setFeedback(res.data);
			});
		} else {
			getFeedBack({}, 3).then(res => {
				setFeedback(res.data);
			});
		}
	}, [vehicle]);

	const rating = (average * 2) / 10;
	const toggle = () => setIsOpen(!isOpen);
	const starRating = rating / 2;

	const prepareData = () => {
		let data: any = {};
		if (currentDepartment === "vehicles") {
			data.brand = vehicle?.brand.name;
			data.construction_year = new Date(
				vehicle?.construction_year as string
			).getUTCFullYear();
			data.fuel_type = vehicle?.fuel?.name;
			data.model = vehicle?.model.name;
		}
		return data;
	};

	return (
		<div className="reviews-wrap">
			<Row className="reviews-wrap-row">
				<Col sm="6">
					<div className="review-heading">
						<div className="rating-number">{rating.toFixed(1)}</div>
						<Col className="rating-text">
							<p className="">
								Gemiddeld uit {total}{" "}
								<FormattedMessage id="header.review" defaultMessage="Review" />
							</p>
							<ReactStars
								className="startrating"
								valueProp={starRating}
								count={5}
								edit={false}
								size={20}
								color2={"#faca30"}
							/>
						</Col>
					</div>
					{feedback.map((item, i) => (
						<FeedbackRow item={item} key={`feedback${i}`} />
					))}
				</Col>
				<Col sm="6" className="d-flex align-items-center">
					{currentDepartment === "vehicles" && (
						<img
							className="youtube-img"
							onClick={toggle}
							alt="youtube"
							width="100%"
							src="youtube-video-img.jpg"
						/>
					)}
					{currentDepartment !== "vehicles" && (
						<img
							style={{
								objectFit: "contain",
								height: "auto",
								borderRadius: "15px"
							}}
							alt="map"
							width="100%"
							src="https://content.vandaag.services/storage/production/system-service/assets/vandaag-service-points-map.png"
						/>
					)}
				</Col>
			</Row>

			<Modal isOpen={isOpen} toggle={toggle} className="video-modal">
				<ModalHeader toggle={toggle}>
					<FormattedMessage id="vehicleModal.youtubeTitle" />
				</ModalHeader>
				<ModalBody>
					<div className="youtube-video-wrapper">
						<iframe
							src="https://www.youtube.com/embed/o-x9ENT2urI?rel=0&autoplay=1"
							frameBorder="0"
							width="600px"
							height="400px"
							allow="autoplay; encrypted-media"
							allowFullScreen
							title="video"
						/>
					</div>
				</ModalBody>
			</Modal>
		</div>
	);
};

export default Reviews;
