import * as apiService from "../index";

/**
 * Get vehicle
 */
export const getVehicle = (lisencePlate: string) => {
	let apiCall = "vehicle-information-service/information";
	if (lisencePlate) {
		apiCall += `?license-plate=${lisencePlate}`;
	}
	return apiService.get(apiCall);
};

/**
 * Get vehicle services
 */
export const getVehicleServices = (payload: any, token: any = null) => {
	if (payload) {
		delete payload.locationData;
		delete payload.preferred_delivery_moments;
		delete payload.preferred_pickup_moments;
		// 	delete payload.image;
		delete payload.id;
	}
	console.log("Services/price payload", payload?.['license-plate'])

	
	// --------------- Start Json to Parms URL --------------------------------

const queryString = (payload: any) => {
	// For Vehicals
	if(payload && payload?.vehicle_id){
	const selectedServicesString = payload?.selected_services.map((service: { id: any; }, index:any) => `selected_services_id[]=${service?.id}`).join('&');
	return `license-plate=${payload?.['license-plate']}&vehicle_id=${payload?.vehicle_id}&${selectedServicesString}`;
	}
	// For Couiers
	if(payload && payload?.transportation_vehicle_id){
	const selectedServicesString = payload?.selected_services.map((service: { id: any; }, index:any) => `selected_services_id[]=${service?.id}`).join('&');
	const dimensionsString = Object.entries(payload?.dimensions).map(([key, value]) => `dimensions[${key}]=${value}`).join('&');
	const fromToStrings = `from=${payload?.from}&to=${payload?.to}&cargo_type=${payload?.cargo_type}&cargo_type_id=${payload?.cargo_type_id}&transportation_vehicle_id=${payload?.transportation_vehicle_id}`;
	return `${fromToStrings}&${dimensionsString}&${selectedServicesString}`;
	}
  };
  const queryData = queryString(payload);


// --------------- End Json to Parms URL --------------------------------


console.log("Im here", payload)

	// return apiService.get(
	// 	"services/prices",
	// 	{
	// 		data: payload && btoa(JSON.stringify(payload))             // For Encoded URL
	// 	},
	// 	token ? { "X-Captcha": token } : {}
	// );

	return apiService.get(
		`services/prices?${ queryData}`,
		token ? { "X-Captcha": token } : {}                           // For Parms URL
	);
};

/**
 * Get vehicle Brands
 */
export const getVehicleBrands = () => {
	return apiService.get("vehicle-information-service/brands");
};

/**
 * Get vehicle Brands
 */
export const getVehicleModels = (brandId: number) => {
	let apiCall = "vehicle-information-service/brands/";
	if (brandId) {
		apiCall += `${brandId}/models`;
	}
	return apiService.get(apiCall);
};

/**
 * Get vehicle Years
 */
export const getVehicleYears = (modelId: number) => {
	let apiCall = `vehicle-information-service/construction-years?model_id=${modelId}`;
	return apiService.get(apiCall);
};

/**
 * Get vehicle Fuels
 */
export const getVehicleFuels = (brandId: number, modelId: number) => {
	let apiCall = "vehicle-information-service/fuels?";
	if (brandId && brandId) {
		apiCall += `model_id=${modelId}&brand_id=${brandId}`;
	}
	return apiService.get(apiCall);
};

/**
 * Get vehicle Fuels
 */
export const setVehicleInformation = (
	brandId: number,
	modelId: number,
	fuelId: number,
	year: number
) => {
	let apiCall = "vehicle-information-service/information?";
	if (brandId && brandId) {
		apiCall += `brand_id=${brandId}&model_id=${modelId}&fuel_id=${fuelId}&construction_year=${year}`;
	}
	return apiService.get(apiCall);
};

/**
 * Order Sync
 */
export const orderSyncService = (req: object) => {
	let apiCall = "orders/order-synchronization";
	return apiService.post(apiCall, req);
};

/**
 * Date Availabilities
 */


// --------------- Start Json to Parms URL --------------------------------

const queryString = (payload: any) => {
	const selectedServicesString = payload.selected_services.map((service: { id: any; price: any; }, index:any) => `selected_services_id[]=${service.id}&selected_services_price[]=${service.price}`).join('&');
	return `from=${payload.from}&to=${payload.to}&${selectedServicesString}`;
  };

// --------------- End Json to Parms URL --------------------------------

export const dateAvailabilities = (payload: object) => {
	console.log("Date aaaaaaaaaaaaaaa", payload)
	// const base64 = `data=${window.btoa(JSON.stringify(payload))}`;
	// const apiCall = `orders/availabilities?data=${base64}`;   // For encoded URL

	const base64 = queryString(payload);
	const apiCall = `orders/availabilities?${base64}`;          // For Parms URL
	return apiService.get(apiCall);
};

/**
 * get autocomplete data
 */

export const getAutocompleteData = (
	houseNumber?: string,
	postalCode?: string
) => {
	let apiCall = `location-service/autocomplete/zip-code?street_number=${houseNumber}&zip_code=${postalCode}`;
	return apiService.get(apiCall);
};

/**
 * content-type (mimetype)
 * content-length-range (filesize)
 * get upload url
 */
export const getUploadUrl = (
	uuid: string,
	contentType: string,
	contentLengthRange: string
) => {
	const apiCall = `booking-service/orders/uploads/issue-upload-link?id=${uuid}&content-type=${encodeURIComponent(
		contentType
	)}&content-length-range=${contentLengthRange}`;
	return apiService.get(apiCall);
};
