// @ts-nocheck
import React, { useState, useEffect } from "react";
import { IStarRating } from "./Interface/StarRatingProps";
import "./styles/StarRating.scss";

const parentStyles = {
	overflow: "hidden",
	position: "relative"
};

const defaultStyles = {
	position: "relative",
	overflow: "hidden",
	cursor: "pointer",
	display: "block",
	float: "left"
};

const getHalfStarStyles = (color, uniqueness) => {
	return `
    .react-stars-${uniqueness}:before {
      position: absolute ;
      overflow: hidden;
      display: block;
      z-index: 1;
      top: 0; left: 0;
      width: 50% ;
      content: attr(data-forhalf);
      color: ${color};
  }`;
};

const ReactStars = ({
	className,
	edit = true,
	half = true,
	valueProp = 0,
	count = 5,
	char = "★",
	size = 15,
	color1 = "gray",
	color2 = "#ffd700",
	onChange = () => {},
	onMouseLeave = () => {},
	onMouseMove = () => {},
	onMouseOver = () => {}
}: IStarRating) => {
	const uniqueness = Math.random() + "".replace(".", "");
	const [value, setValue] = useState(valueProp || 0);
	const [stars, setStars] = useState([]);
	const [halfStar, sethalfStar] = useState({
		at: Math.floor(valueProp),
		hidden: half && value % 1 < 0.5
	});
	const [config, setConfig] = useState({
		count,
		size,
		char,
		// default color of inactive star
		color1,
		// color of an active star
		color2,
		half,
		edit
	});

	useEffect(() => {
		setStars(getStars(value));
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		setStars(getStars(valueProp));
	}, [valueProp]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		setStars(getStars(valueProp));
		setValue(valueProp);
		sethalfStar({
			at: Math.floor(valueProp),
			hidden: config.half && valueProp % 1 < 0.5
		});
		setConfig(
			Object.assign({}, config, {
				count,
				size,
				char,
				color1,
				color2,
				half,
				edit
			})
		);
	}, [value, count, size, char, color1, color2, half, edit]); // eslint-disable-line react-hooks/exhaustive-deps

	const isDecimal = value => {
		return value % 1 !== 0;
	};

	const getRate = () => {
		let stars;
		if (config.half) {
			stars = Math.floor(value);
		} else {
			stars = Math.round(value);
		}
		return stars;
	};

	const getStars = activeCount => {
		if (typeof activeCount === "undefined") {
			activeCount = getRate();
		}
		let stars = [];
		for (let i = 0; i < config.count; i++) {
			stars.push({
				active: i <= activeCount - 1
			});
		}
		return stars;
	};

	const mouseOver = event => {
		if (!config.edit) return;

		const { value, index } = calcData(event, config, halfStar);

		setStars(getStars(index));
		onMouseOver(value);
	};

	const mouseMove = event => {
		if (!config.edit) return;

		const { value, index } = calcData(event, config, halfStar);

		setStars(getStars(index));
		onMouseMove(value);
	};

	const moreThanHalf = (event, size) => {
		let { target } = event;
		var mouseAt = event.clientX - target.getBoundingClientRect().left;
		mouseAt = Math.round(Math.abs(mouseAt));
		return mouseAt > size / 2;
	};

	const mouseLeave = () => {
		if (!config.edit) return;
		if (config.half) {
			halfStar.hidden = !isDecimal(value);
			halfStar.at = Math.floor(value);
		}
		setStars(getStars());
		onMouseLeave(value);
	};

	const clicked = event => {
		if (!config.edit) return;

		const { value, index } = calcData(event, config, halfStar);

		setStars(getStars(index));
		setValue(value);
		onChange(value);
	};

	const calcData = (event, config, halfStar) => {
		let index = Number(event.target.getAttribute("data-index"));
		let value;
		if (config.half) {
			const isAtHalf = moreThanHalf(event, config.size);
			halfStar.hidden = isAtHalf;
			if (isAtHalf) index = index + 1;
			value = isAtHalf ? index : index + 0.5;
			halfStar.at = index;
		} else {
			value = index = index + 1;
		}

		return { value, index };
	};

	const renderHalfStarStyleElement = () => {
		return (
			<style
				dangerouslySetInnerHTML={{
					__html: getHalfStarStyles(config.color2, uniqueness)
				}}
			></style>
		);
	};

	const renderStars = () => {
		const { color1, color2, size, char, half, edit } = config;
		return stars.map((star, i) => {
			let starClass = "";
			if (half && !halfStar.hidden && halfStar.at === i) {
				starClass = `react-stars-${uniqueness} halfStar`;
			}
			const style = Object.assign({}, defaultStyles, {
				color: star.active ? color2 : color1,
				cursor: edit ? "pointer" : "default",
				fontSize: `${size}px`
			});
			return (
				<span
					className={starClass}
					style={style}
					key={i}
					data-index={i}
					data-forhalf={char}
					onMouseOver={mouseOver}
					onMouseMove={mouseMove}
					onMouseLeave={mouseLeave}
					onClick={clicked}
				>
					{char}
				</span>
			);
		});
	};

	return (
		<div className={className} style={parentStyles}>
			{config.half ? renderHalfStarStyleElement() : ""}
			{renderStars()}
		</div>
	);
};

export default ReactStars;
