import React, { useState, useEffect } from "react";
import { Modal, ModalBody, ModalHeader, Button } from "reactstrap";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";

import { IServiceCodePopupProps } from "component/ServiceCodePopup/Interface/props/ServiceCodePopupProps";
import { getServiceCode } from "store/apiService/order/orderServices";
import { getCredentials } from "common/utilities/department/department";
import "component/ServiceCodePopup/styles/ServiceCodePopup.scss";
import { useDispatch, useSelector } from "react-redux";
import { IRootReducerState } from "common/interface/store/reducer/Reducer";
import { synchronizeOrderAction } from "store/actions/order/orderActions";
import { useIsShowSupportPhone } from "util/customHooks";

function ServiceCodePopup({
	phoneNumber,
	department,
	onClick,
	isOpenServiceCodePopUp,
	isDangerAlert
}: IServiceCodePopupProps) {
	const dispatch = useDispatch();
	const isShowSupportPhone = useIsShowSupportPhone();
	const [serviceCode, setServiceCode] = useState<string>("");
	const [error, setError] = useState(false);

	/**
	 * Get last synced data
	 */
	const lastSyncedData = useSelector((state: IRootReducerState) => {
		return state.orderReducer.lastSyncedData;
	});

	useEffect(() => {
		if (isOpenServiceCodePopUp && department) {
			dispatch(synchronizeOrderAction(lastSyncedData, department));
			getServiceCode(getCredentials(department))
				.then(res => {
					setServiceCode(res.code.toString());
				})
				.catch(e => {
					setError(true);
				});
		}
	}, [department, isOpenServiceCodePopUp]);

	const reload = () => {
		window.location.reload();
	};

	return (
		<Modal
			isOpen={isOpenServiceCodePopUp}
			className={classnames("service-code-popup", {
				"alert-box-popupss": isDangerAlert
			})}
			toggle={onClick}
		>
			{!isDangerAlert && <ModalHeader toggle={onClick} />}
			<ModalBody>
				{department && (
					<>
						{isShowSupportPhone && (
							<div className="code-wrapper service-code-label">
								<span>
									<FormattedMessage
										id="serviceCodePopup.call"
										defaultMessage="CALL : "
									/>
								</span>
								{phoneNumber && (
									<span className="service-code">
										<a href={`tel:${phoneNumber.replace(/ /g, "")}`}>
											{phoneNumber}
										</a>
									</span>
								)}
								<span>
									<FormattedMessage
										id="serviceCodePopup.free"
										defaultMessage="(Free)"
									/>
								</span>
							</div>
						)}
						<p className="service-code-about">
							{!isDangerAlert && (
								<FormattedMessage
									id="serviceCodePopup.provideNumber"
									defaultMessage="provide this number via customer service so that they can monitor your booking"
								/>
							)}
							{isDangerAlert && (
								<FormattedMessage
									id="alertBox.text"
									defaultMessage="provide this number via customer service so that they can monitor your booking"
								/>
							)}
						</p>

						<h3 className="service-code-heading">
							{department && !isDangerAlert && (
								<FormattedMessage
									id="serviceCodePopup.serviceCode"
									defaultMessage="This is your service code"
								/>
							)}
						</h3>
						<div className="service-code-quantity">
							{serviceCode.length && !error ? (
								`${serviceCode.slice(0, 3)} ${serviceCode.slice(3)}`
							) : error ? (
								<FormattedMessage
									id="alertBox.unableTogetServiceCode"
									defaultMessage="Oops unfortunately something went wrong"
								/>
							) : (
								"Loading..."
							)}
						</div>
					</>
				)}
				<h3 className="service-code-heading">
					{!department && (
						<FormattedMessage
							id="serviceCodePopup.retryMessage"
							defaultMessage="Try again later"
						/>
					)}
					{department && isDangerAlert && (
						<FormattedMessage
							id="alertBox.title"
							defaultMessage="Oops unfortunately something went wrong"
						/>
					)}
				</h3>

				{!department && (
					<Button className="retry-btn" onClick={reload}>
						<FormattedMessage
							id="serviceCodePopup.retryButtonText"
							defaultMessage="Reload"
						/>
					</Button>
				)}
			</ModalBody>
		</Modal>
	);
}

export default ServiceCodePopup;
