/**
 * Register all action related couriers here
 */
import {
	getCurrentDepartment,
	getFeedBack,
	getFeedBackData,
	payInvoice,
	payOrder,
	quoteRequest,
	submitFeedBackData
} from "../../apiService/department/departmentServices";
import { attachDepartment } from "../../apiService";
import {
	GET_CURRENT_DEPARTMENT,
	SET_COURIER_LOCATION,
	SET_FEEDBACK,
	SHOW_ERROR
} from "../../actionTypes/department/departmentActionTypes";

/**
 * show_inactive_collaborators
 * show_collaborators
 * Handle get couriers action
 * @param {string} department
 */
export const getCurrentDepartmentAction = (department?: string) => {
	return (dispatch: any) => {
		getCurrentDepartment(department)
			.then((currentDepartment: any) => {
				attachDepartment(currentDepartment.data.slug);
				return dispatch({
					type: GET_CURRENT_DEPARTMENT,
					payload: Object.assign({}, {
						show_inactive_collaborators: false,
						show_collaborators: true
					}, currentDepartment.data)
				});
			})
			.catch((err: any) => {
				dispatch({
					type: SHOW_ERROR,
					payload: err.response
				});
			});
	};
};

/**
 * Handle set courier locations action
 * @param {string} locations
 */
export const setCourierLocations = (locations: any) => {
	localStorage.setItem("locationData", JSON.stringify(locations));
	return {
		type: SET_COURIER_LOCATION,
		payload: locations
	};
};

/**
 * Handle set feedback action
 */
export const getFeedbackAction = (data: any, minimum: number) => {
	return (dispatch: any) => {
		getFeedBack(data, minimum)
			.then((response: any) => {
				return dispatch({
					type: SET_FEEDBACK,
					payload: response.data
				});
			})
			.catch(error => console.log(error));
	};
};

/**
 * Handle get feedback action
 */
export const getFeedbackDataAction = (
	id: number,
	hash: string,
	callback: (staus: boolean, res: any) => void
) => {
	return () => {
		getFeedBackData(id, hash)
			.then((response: any) => {
				callback(true, response.data);
			})
			.catch(error =>
				callback(
					true,
					error.response ? error.response.data.message : "Something went wrong."
				)
			);
	};
};

/**
 * Handle submit feedback action
 */
export const submitFeedbackDataAction = (
	id: string,
	hash: any,
	data: { content: string; rating: number },
	callBack: (status: boolean, response: any) => void
) => {
	return (dispatch: any) => {
		submitFeedBackData(id, hash, data)
			.then((response: any) => {
				callBack(true, response);
			})
			.catch(error =>
				callBack(
					false,
					error.response ? error.response.data : "Something went wrong."
				)
			);
	};
};

/**
 * Handle pay invoice
 */
export const payInvoiceAction = (
	invoiceId: string,
	hash: string,
	data: { cancel_url: string; success_url: string },
	callBack: (staus: boolean, res: any) => void
) => {
	return () => {
		payInvoice(invoiceId, hash, data)
			.then((response: any) => {
				callBack(true, response);
			})
			.catch(error => callBack(false, error));
	};
};

/**
 * Handle pay order
 */
export const payOrderAction = (
	orderId: string,
	hash: string,
	callBack: (staus: boolean, res: any) => void
) => {
	return () => {
		payOrder(orderId, hash)
			.then((response: any) => {
				callBack(true, response);
			})
			.catch(error => callBack(false, error));
	};
};

/**
 * Handle pay invoice
 */
export const requestQuoteAction = (
	data: any,
	department: string,
	callBack: (staus: boolean, res: any) => void
) => {
	return () => {
		quoteRequest(data, department)
			.then((response: any) => {
				callBack(true, response);
			})
			.catch((err: any) => {
				// let errorData: IOrderErrorState = {
				// 	errorData: err.response && err.response.data,
				// 	status: err.response.status
				// };
				callBack(false, err.response && err.response.data);
			});
	};
};
