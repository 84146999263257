import { initialDepartmentState } from "./InitialDepartmentState";
import { IAction } from "common/interface/store/action/Action";
import { createIntl, createIntlCache } from "react-intl";
import {
	IDepartmentState,
	IFeedbackData
} from "../../interface/department/Department";
import {
	GET_CURRENT_DEPARTMENT,
	SET_COURIER_LOCATION,
	SET_FEEDBACK,
	SHOW_ERROR,
	UPDATE_IDLE_STATE
} from "../../actionTypes/department/departmentActionTypes";
import AppLocale from "lngProvider";

const cache = createIntlCache();
/**
 *
 * Departments reducer which handle all action related couriers
 *
 * @param state
 * @param action
 */
export default (
	state: IDepartmentState = initialDepartmentState,
	action: IAction
): IDepartmentState => {
	const locale = state.currentDepartment?.config.default_locale || "nl_NL";
	const lang: any = locale.split("_")[0];
	// @ts-ignore
	const langLocale = AppLocale[lang];
	const intl = createIntl(
		{ locale: lang, messages: langLocale.messages },
		cache
	);
	switch (action.type) {
		case GET_CURRENT_DEPARTMENT:
			let department = action.payload;
			if (!department.steps) {
				if (department.slug === "couriers") {
					department = {
						...action.payload,
						steps: [
							{
								description: intl.formatMessage({
									id: "steps.1Couriers.description"
								}),
								heading: intl.formatMessage({
									id: "steps.1.heading"
								})
							},
							{
								description: intl.formatMessage({
									id: "steps.2Couriers.description"
								}),
								heading: intl.formatMessage({
									id: "steps.1.heading"
								})
							},
							{
								description: intl.formatMessage({
									id: "steps.3Couriers.description"
								}),
								heading: intl.formatMessage({
									id: "steps.3.heading"
								})
							}
						]
					};
				} else if (
					[
						"jobs",
						"beauty",
						"boats",
						"motorcycle",
						"driving-schools",
						"entertainment",
						"bicycles",
						"legal",
						"cleaning",
						"scooters",
						"electronics",
						"financial"
					].includes(department.slug)
				) {
					department = {
						...action.payload,
						steps: [
							{
								description: intl.formatMessage({
									id: `steps.1.${department.slug}.description`
								}),
								heading: intl.formatMessage({
									id: "steps.1.heading"
								})
							},
							{
								description: intl.formatMessage({
									id: `steps.2.${department.slug}.description`
								}),
								heading: intl.formatMessage({
									id: "steps.1.heading"
								})
							},
							{
								description: intl.formatMessage({
									id: `steps.3.${department.slug}.description`
								}),
								heading: intl.formatMessage({
									id: "steps.3.heading"
								})
							}
						]
					};
				} else {
					department = {
						...action.payload,
						steps: [
							{
								description: intl.formatMessage({
									id: "steps.1.description"
								}),
								heading: intl.formatMessage({
									id: "steps.1.heading"
								})
							},
							{
								description: intl.formatMessage({
									id: "order.description"
								}),
								heading: intl.formatMessage({
									id: "steps.2.heading"
								})
							},
							{
								description: intl.formatMessage({
									id: "department.title"
								}),
								heading: intl.formatMessage({
									id: "steps.3.heading"
								})
							}
						]
					};
				}
			}
			return {
				...state,
				currentDepartment: department
			};
		case SET_COURIER_LOCATION:
			return {
				...state,
				location: action.payload
			};
		case SHOW_ERROR:
			return {
				...state,
				error: true
			};

		case SET_FEEDBACK:
			return {
				...state,
				feedback: generateFeedback(action.payload)
			};
		case UPDATE_IDLE_STATE:
			return {
				...state,
				isUserIdle: action.payload
			};

		default:
			return state;
	}
};

const generateFeedback = (payload: IFeedbackData[]) => {
	let data: IFeedbackData[] = [];
	payload.forEach((item: IFeedbackData) => {
		data.push({
			id: item.id,
			name: item.name ? item.name : "",
			rating: item.rating,
			content: item.content,
			created_at: item.created_at,
			tags: {
				brand: item.tags && item.tags.brand,
				city: item.tags && item.tags.city,
				construction_year: item.tags && item.tags.construction_year,
				model: item.tags && item.tags.model
			}
		});
	});
	return data;
};
