import React from "react";

import "./styles/FeedbackCard.scss";
import { Card } from "reactstrap";
import { IFeedbackCardProps } from "./Interface/props/FeedbackCardProps";
import ReactStars from "component/StarRating";

const FeedbackCard = ({ item }: IFeedbackCardProps) => {
	const starRating = item.rating / 2;

	return (
		<Card className="feedback-card-wrap">
			<ReactStars
				className="startrating"
				valueProp={starRating}
				count={5}
				edit={false}
				size={20}
				color2={"#faca30"}
			/>
			<p style={{ marginTop: "5px" }}>
				<b>{item.name}</b>
			</p>
			{item.tags.model && <p>{item.tags.model}</p>}
			<p>{item.content}</p>
		</Card>
	);
};

export default FeedbackCard;
