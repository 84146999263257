import React from "react";
import { useSelector } from "react-redux";
import { IRootReducerState } from "common/interface/store/reducer/Reducer";

import "./styles/ComingSoon.scss";

const ComingSoon = () => {
	const currentDepartmentLogo = useSelector(
		(state: IRootReducerState) =>
			state.departmentReducer.currentDepartment?.image.large
	);

	return (
		<div className="coming-soon">
			<div className="content p-4 d-flex align-items-center justify-content-center flex-column text-center">
				<img
					src={currentDepartmentLogo}
					width="550px"
					className="mb-3"
					alt="logo"
				/>
				<h1 className="mt-3">
					<span>Vandaag Financieel</span> komt eraan!
				</h1>

				<p className="mt-4">
					Kijk in de tussentijd alvast op{" "}
					<strong>
						<a href="https://vandaag.services">vandaag.services</a>
					</strong>
					,
					<strong>
						<a
							href="http://vandaag-autos.nl/"
							target="_blank"
							rel="noopener noreferrer"
						>
							Vandaag-Autos.nl
						</a>
					</strong>{" "}
					&{" "}
					<strong>
						<a
							className="c-link"
							href="http://vandaag-koeriers.nl/"
							target="_blank"
							rel="noopener noreferrer"
						>
							Vandaag-Koeriers.nl
						</a>
					</strong>
				</p>

				<form action="" method="" className="mt-4">
					<div className="form-group d-flex flex-column flex-sm-row align-items-center justify-content-start">
						<input
							type="email"
							className="form-control"
							name="coming-soon-email"
							id="coming-soon-email"
							placeholder="Email"
						/>
						<input
							type="submit"
							className="btn btn-success ml-sm-2 mt-3 mt-sm-0"
							value="Notify me"
						/>
					</div>
				</form>
			</div>
		</div>
	);
};

export default ComingSoon;
