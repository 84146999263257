import React, { FC, useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Col, Container, Row, Card } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";

import "react-material-stepper/dist/react-stepper.css";
import "views/StepperManager/styles/StepperManager.scss";
import Stepper from "component/Stepper";
import ServiceCodePopup from "component/ServiceCodePopup/ServiceCodePopup";
import Reviews from "component/Reviews";
import Calculate from "../Calculate";
import Order from "../Order";
import StepperContext from "common/StepperContext";
import { IRootReducerState } from "common/interface/store/reducer/Reducer";
import {
	getVehicleAction,
	getVehicleServicesAction
} from "store/actions/vehicles/vehiclesActions";
import { setCourierLocations } from "store/actions/department/deparmentActions";
import { BEEN_ON_SECOND_STEP } from "store/actionTypes/order/orderActionTypes";

const StepperManager: FC = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	const history = useHistory();
	const useQuery = () => new URLSearchParams(useLocation().search);
	const query = useQuery();
	const [activeStep, setActiveStep] = useState<number>(
		location.pathname === "/order" ? 2 : 1
	);
	const [isOpenServiceCodePopUp, setOpenServiceCodePopUp] = useState<boolean>(
		false
	);
	const serviceCodePopUp = () =>
		setOpenServiceCodePopUp(!isOpenServiceCodePopUp);

	/**
	 * get current department form redux
	 */
	let currentDepartment = useSelector(
		(state: IRootReducerState) => state.departmentReducer.currentDepartment
	);

	/**
	 * Get synced data
	 */
	const syncedData = useSelector((state: IRootReducerState) => {
		return state.orderReducer.syncedData;
	});

	/**
	 * Hide tawkto on mobile devices
	 */
	// useEffect(() => {
	// 	// @ts-ignore
	// 	if (window.Tawk_API && window.innerWidth <= 425) {
	// 		var styleSheet = document.createElement("style");
	// 		const css = `@media screen and (max-width: 480px) {
	// 			.tawk-mobile {
	// 				display: none;
	// 			}
	// 		}`;
	// 		styleSheet.innerText = css;
	// 		let hideTawkToInterval = setInterval(() => {
	// 			// @ts-ignore
	// 			if (window.document.querySelector('[width="67px"]')) {
	// 				// @ts-ignore
	// 				window.document.querySelector('[width="67px"]').contentWindow.document.head.append(styleSheet);
	// 				clearInterval(hideTawkToInterval);
	// 			}
	// 		}, 300);
	// 	}
	// }, []);

	/**
	 * get vehicle data from synced data plate numbet only when user directly goes to 2nd step when on 1st step vehicle popup gets the vehicle data
	 */
	useEffect(() => {
		if (
			syncedData &&
			syncedData !== "Known error occured" &&
			location.pathname === "/order"
		) {
			if (syncedData["license-plate"]) {
				dispatch(getVehicleAction(syncedData["license-plate"]));
			}
			dispatch(getVehicleServicesAction(syncedData));
		}
	}, [dispatch, location, syncedData]);

	/**
	 * set location to redux if exist in localstorage
	 */
	useEffect(() => {
		if (
			currentDepartment?.slug === "couriers" &&
			(localStorage.getItem("locationData") || syncedData.locationData)
		) {
			if (localStorage.getItem("locationData")) {
				dispatch(
					setCourierLocations(
						JSON.parse(localStorage.getItem("locationData") as string)
					)
				);
			} else if (syncedData.locationData) {
				dispatch(setCourierLocations(JSON.parse(syncedData.locationData)));
				localStorage.setItem("locationData", syncedData.locationData);
			}
		}
	}, [dispatch, currentDepartment, syncedData]);

	const gotoSecondStep = () => {
		if (activeStep !== 2) {
			dispatch({
				type: BEEN_ON_SECOND_STEP
			});
			setActiveStep(2);
			history.push("/order");
		}
	};

	const getActiveStep = () => {
		switch (location.pathname) {
			case "/calculate":
				if (query.get("external_id")) {
					localStorage.setItem(
						`${currentDepartment?.slug}-externalId`,
						query.get("external_id") || ""
					);
				}
				if (query.get("preferred_service_point_id")) {
					localStorage.setItem(
						`${currentDepartment?.slug}-service_point_id`,
						query.get("preferred_service_point_id") || ""
					);
				}
				return (
					<StepperContext.Provider
						value={{
							gotoSecondStep,
							setOpenServiceCodePopUp,
							servicePopup: isOpenServiceCodePopUp
						}}
					>
						<Calculate />
					</StepperContext.Provider>
				);
			case "/order":
				dispatch({
					type: BEEN_ON_SECOND_STEP
				});
				return <Order />;
		}
	};

	const stepClick = (step: number) => {
		switch (step) {
			case 1: {
				if (activeStep !== 1) {
					setActiveStep(1);
					history.push("/calculate");
				}
				break;
			}
			case 2: {
				gotoSecondStep();
				break;
			}
			case 3: {
				// setActiveStep(2);
				break;
			}
			default: {
				setActiveStep(1);
				break;
			}
		}
	};

	return (
		<Container fluid="lg" className="calculate">
			<Stepper
				department={currentDepartment?.slug || ""}
				activeStep={activeStep}
				steps={currentDepartment?.steps}
				callBack={stepClick}
			></Stepper>
			{getActiveStep()}
			{activeStep === 2 ? (
				<Row style={{ marginTop: "30px", opacity: "0.8" }}>
					<Col sm="12">
						<Card style={{ padding: "0 20px" }}>
							<Reviews
								currentDepartment={currentDepartment?.slug || "couriers"}
								average={currentDepartment?.feedback?.average || 0}
								total={currentDepartment?.feedback?.total || 0}
							/>
						</Card>
					</Col>
				</Row>
			) : null}
			<Row>
				<Col sm="12" md={{ size: 4, offset: 4 }}>
					<Button
						onClick={serviceCodePopUp}
						color="primary"
						className="btn-offerte"
						size="sm"
						block
					>
						<FormattedMessage
							id="stepperManager.hallService"
							defaultMessage="Request service code"
						/>
					</Button>
				</Col>
			</Row>
			{
				<ServiceCodePopup
					onClick={serviceCodePopUp}
					isOpenServiceCodePopUp={isOpenServiceCodePopUp}
					department={currentDepartment?.slug || ""}
					phoneNumber={
						(currentDepartment &&
							`${currentDepartment.support_phone.substr(0, 3)}
              ${currentDepartment.support_phone.substr(3, 4)}
              ${currentDepartment.support_phone.substr(7, 3)}`) ||
						""
					}
				/>
			}
		</Container>
	);
};

export default StepperManager;
