import React, { FC } from "react";

import Header from "./Header";
import { IMasterLayoutProps } from "./Interface/props/MasterLayoutProps";

/**
 * Sample component for greeting
 * @param props
 * @constructor
 */
const MasterLayout: FC<IMasterLayoutProps> = props => {
	// destructure all props here
	const { currentDepartment } = props;

	return (
		<React.Fragment>
			<Header currentDepartment={currentDepartment} />
			{props.children}
		</React.Fragment>
	);
};

export default MasterLayout;
