import React, { FC } from "react";
import { Row, Col, Input, Button } from "reactstrap";
import { useIntl } from "react-intl";

import "./styles/Unsubscribe.scss";

const UnsubscribePage: FC = () => {
	const intl = useIntl();
	const Email = intl.formatMessage({
		id: "courierDetail.email",
		defaultMessage: "Email"
	});
	const Heading = intl.formatMessage({
		id: "unsubscribe.heading",
		defaultMessage: "UNSUBSCRIBE FOR VANDAAG OFFERS, ACTIONS AND DISCOUNTS"
	});
	const Description = intl.formatMessage({
		id: "unsubscribe.description",
		defaultMessage:
			"If you no longer wish to receive offers, promotions and discounts, you can unsubscribe using the form below. You will then no longer receive offers, promotions and discounts at the email address you provided."
	});
	const ButtonText = intl.formatMessage({
		id: "unsubscribe.button",
		defaultMessage: "Unsubscribe"
	});
	return (
		<div className="main-wrapper">
			<h3>{Heading}</h3>
			<p>{Description}</p>
			<Row>
				<Col md={8}>
					<Input type="text" name="email" placeholder={Email} />
				</Col>
				<Col md={4}>
					<Button className="unsubscribe-btn">{ButtonText}</Button>
				</Col>
			</Row>
		</div>
	);
};

export default UnsubscribePage;
