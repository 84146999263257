export const formatPrice = (price: number | string) => {
	if (price === "GRATIS" || price === "FREE") {
		return price;
	}
	if (price.toString().length > 2) {
		const priceStr = price.toString();
		return (
			priceStr.slice(0, priceStr.length - 2) +
			"," +
			priceStr.slice(priceStr.length - 2)
		);
	} else if (price) {
		return price;
	}
	return "0,00";
};
