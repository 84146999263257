import React, { useCallback, useEffect, useState, useRef } from "react";
import { Button, Col, Input, Row, Alert } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";

import "./Styles/dimensionStyle.scss";
import { ICourier } from "store/interface/shipment/Shipment";
import { useDispatch, useSelector } from "react-redux";
import { IRootReducerState } from "common/interface/store/reducer/Reducer";
import { ICourierDimensionSelectionProps } from "./Interface/props/ICourierDimensionSelectionProps";
import { synchronizeOrderAction } from "store/actions/order/orderActions";
import { getCourierVehicleAction } from "store/actions/shipment/shipmentActions";
import ShipmentDetailsInput from "../ShipmentDetailsInput";

const MAX_HEIGHT = 500;
const MAX_WIDTH = 225;
const MAX_LENGTH = 755;
const MAX_WEIGHT = 3000;
const phoneLink = React.createElement(
	"a",
	{ href: `tel:088 - 2222 001` },
	"088 - 2222 001"
);
const CourierDimensionSelection: React.FC<ICourierDimensionSelectionProps> = (
	props: any
) => {
	const intl = useIntl();
	const heightText = intl.formatMessage({
		id: "shipment.height",
		defaultMessage: "height"
	});
	const widthText = intl.formatMessage({
		id: "shipment.width",
		defaultMessage: "width"
	});
	const lengthText = intl.formatMessage({
		id: "shipment.length",
		defaultMessage: "length"
	});
	const weightText = intl.formatMessage({
		id: "shipment.weight",
		defaultMessage: "weight"
	});
	const describePlaceholder = intl.formatMessage({
		id: "shipment.shipmentModalOtherDescribe",
		defaultMessage: "Describe your type of shipment"
	});
	const heightDescription = intl.formatMessage(
		{
			id: "shipment.cmDescription",
			defaultMessage:
				"If you want to send a package that is greater than the maximum {dimentionKey}, please contact us via 088 - 2222 001 or via chat."
		},
		{ dimentionKey: heightText, link: phoneLink }
	);
	const widthDescription = intl.formatMessage(
		{
			id: "shipment.cmDescription",
			defaultMessage:
				"If you want to send a package that is greater than the maximum {dimentionKey}, please contact us via 088 - 2222 001 or via chat."
		},
		{ dimentionKey: widthText, link: phoneLink }
	);
	const lengthDescription = intl.formatMessage(
		{
			id: "shipment.cmDescription",
			defaultMessage:
				"If you want to send a package that is greater than the maximum {dimentionKey}, please contact us via 088 - 2222 001 or via chat."
		},
		{ dimentionKey: lengthText, link: phoneLink }
	);
	const kgDescription = intl.formatMessage(
		{
			id: "shipment.kgDescription",
			defaultMessage:
				"From 200 kg. the tail lift is made mandatory. If you want to have a shipment executed which is heavier than the maximum weight, please contact us at 088 - 2222 001"
		},
		{ link: phoneLink }
	);

	const [selectedCourier, setSelectedCourier] = useState<ICourier>({
		id: 12345,
		order: 99999,
		name: "Select shipment type",
		key: null,
		image: "",
		length: 0,
		height: 0,
		width: 0,
		weight: 0
	});

	/**
	 * Get selected services
	 */
	const lastSyncedData = useSelector((state: IRootReducerState) => {
		return state.orderReducer.lastSyncedData;
	});

	const submitBtnRef = useRef<any>();
	const [height, setHeight] = useState<number>(0);
	const [width, setWidth] = useState<number>(0);
	const [length, setLength] = useState<number>(0);
	const [weight, setWeight] = useState<number>(0);
	const [showInput, setShowInput] = useState<boolean>(false);
	const [inputText, setInputText] = useState<string>("");

	const [error, setError] = useState<boolean>(false);
	const [maxDimentionError, setMaxDimentionError] = useState<boolean>(false);
	const [errorMsg, setErrorMsg] = useState<string>("");

	/**
	 * Dispatch action
	 */
	const dispatch = useDispatch();

	/**
	 * Get shipment state
	 */
	const shipmentReducer = useSelector(
		(state: IRootReducerState) => state.shipmentReducer
	);

	/**
	 * Get selected services
	 */
	const selectedServices = useSelector((state: IRootReducerState) => {
		return state.orderReducer.selectedServices;
	});

	/**
	 * Get synced data
	 */
	const syncedData = useSelector((state: IRootReducerState) => {
		return state.orderReducer.syncedData;
	});

	const onChangeShipmentType = (id: number | string, key: string | null) => {
		const val = shipmentReducer.courierList.find(
			(courier: ICourier) => courier.id === +id
		);
		setSelectedCourier(val as ICourier);
		if (key === "custom") {
			setShowInput(true);
		} else {
			if (window.innerWidth <= 425) {
				setTimeout(() => {
					submitBtnRef.current.scrollIntoView({
						behavior: "smooth",
						inline: "end",
						block: "end"
					});
				}, 200);
			}
		}
	};

	useEffect(() => {
		setHeight(selectedCourier.height);
		setWidth(selectedCourier.width);
		setLength(selectedCourier.length);
		setWeight(selectedCourier.weight);
	}, [selectedCourier]);

	useEffect(() => {
		if (syncedData.cargo_type && syncedData.image && syncedData.dimensions) {
			setSelectedCourier({
				name: syncedData.cargo_type,
				image: syncedData.image,
				order: syncedData.order,
				key: syncedData.key,
				id: syncedData.id,
				length: syncedData.dimensions.length,
				height: syncedData.dimensions.height,
				width: syncedData.dimensions.width,
				weight: syncedData.dimensions.weight
			});
		} else if (syncedData.inputText) {
			setShowInput(true);
			setInputText(syncedData.inputText);
			setHeight(syncedData.dimensions.height);
			setWidth(syncedData.dimensions.width);
			setLength(syncedData.dimensions.length);
			setWeight(syncedData.dimensions.weight);
		}
	}, [syncedData]);

	const locationData = useSelector(
		(state: IRootReducerState) => state.departmentReducer.location
	);

	const getCourierData = (name: string, dimension: any) => {
		shipmentReducer.courierList.forEach((courier: any) => {
			if (courier.name === name) {
				setSelectedCourier({
					...courier,
					height: dimension.height,
					width: dimension.width,
					length: dimension.length,
					weight: dimension.weight
				});
			}
		});
	};

	useEffect(() => {
		if (lastSyncedData) {
			if (lastSyncedData.cargo_type && lastSyncedData.dimensions) {
				setHeight(lastSyncedData.dimensions.height);
				setWidth(lastSyncedData.dimensions.width);
				setLength(lastSyncedData.dimensions.length);
				setWeight(lastSyncedData.dimensions.weight);
				getCourierData(lastSyncedData.cargo_type, lastSyncedData.dimensions);
			}
		}
	}, [lastSyncedData]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (inputText) {
			setSelectedCourier({
				id: selectedCourier.id,
				order: selectedCourier.order,
				name: inputText ? inputText : "Select shipment type",
				key: "custom",
				image: "",
				length,
				height,
				width,
				weight
			});
		}
	}, [inputText]);

	const syncData = useCallback(
		(
			serviceIds: number[],
			locationData,
			selectedCourier = null,
			showInput,
			inputText,
			shipmentReducer = null,
			selectedWidth = 0,
			selectedHeight = 0,
			selectedLength = 0,
			selectedWeight = 0
		) => {
			if (locationData.locationsLatLng.length) {
				const externalId = localStorage.getItem("couriers-externalId");
				const preferredServicePointId = localStorage.getItem(
					"couriers-service_point_id"
				);
				const data: any = {
					from: locationData.locationsLatLng[0].placeId,
					to: locationData.locationsLatLng[1].placeId,
					selected_services: serviceIds
				};

				data.dimensions = {
					width: selectedWidth,
					height: selectedHeight,
					length: selectedLength,
					weight: selectedWeight
				};

				if (selectedCourier) {
					data.cargo_type_id = selectedCourier.id;
					data.cargo_type = selectedCourier.name;
					data.image = selectedCourier.image;
					data.id = selectedCourier.id;
					data.order = selectedCourier.order;
				}
				if (showInput) {
					data.cargo_type_text = inputText;
				}
				if (externalId) {
					data.external_id = externalId;
				}
				if (preferredServicePointId) {
					data.preferred_service_point_id = preferredServicePointId;
				}
				if (shipmentReducer) {
					data.preferred_pickup_moments = [
						{
							datetime: shipmentReducer.toDate
						}
					];
					data.preferred_delivery_moments = [
						{
							datetime: shipmentReducer.fromDate
						}
					];
				}

				if (localStorage.getItem("locationData")) {
					data.locationData = localStorage.getItem("locationData");
				}
				dispatch(synchronizeOrderAction(data, "couriers"));
			}
		},
		[dispatch]
	);

	/**
	 *
	 * prepare base64 data to get courier vehicle
	 */
	const preparePayload = () => {
		let data: any = {
			from: locationData.locationsLatLng[0].placeId,
			to: locationData.locationsLatLng[1].placeId
			// from: "ChIJ2UKSpUQWxkcRudD-eOl3t1g",
			// to: "ChIJ_fE1UpYXxkcRtEPn2pUMoH0",
		};
		if (selectedCourier.id && selectedCourier.weight) {
			data = {
				...data,
				cargo_type: selectedCourier.id,
				cargo_type_id: selectedCourier.id,
				dimensions: {
					height: height,
					weight: weight,
					length: length,
					width: width
				}
			};
		} else if (showInput && inputText) {
			data = {
				...data,
				cargo_type: inputText,
				dimensions: {
					height,
					weight,
					length,
					width
				}
			};
			// setSelectedCourier({
			// 	id: 12345,
			// 	order: 99999,
			// 	name: inputText,
			// 	image: "",
			// 	key: null,
			// 	length: length,
			// 	height: height,
			// 	width: width,
			// 	weight: weight
			// });
		}

		// --------------- Start Json to Parms URL --------------------------------
		const queryString = (data: any) => {
			const dimensionsString = Object.entries(data.dimensions).map(([key, value]) => `dimensions[${key}]=${value}`).join('&');
			const fromToString = `from=${data.from}&to=${data.to}`;
			const cargoTypesString = `cargo_type=${data.cargo_type}&cargo_type_id=${data.cargo_type_id}`;
		  
			return `${cargoTypesString}&${dimensionsString}&${fromToString}`;
		};

		const base64 = queryString(data); 
		
		// --------------- End Json to Parms URL --------------------------------

		console.log("Couriersssssssssssssssssss", base64)
		console.log("Couriersssssssssssssssssss2222", data)
		console.log("Courierssssssssssssssssss11", window.btoa(JSON.stringify(data)))
		return `data=${window.btoa(JSON.stringify(data))}`;    // for encoded url
		// return base64;                                  // for parms url
	};

	const onSubmit = () => {
		if (
			(selectedCourier.key !== "custom" || (showInput && inputText)) &&
			height !== 0 &&
			height <= MAX_HEIGHT &&
			weight !== 0 &&
			weight <= MAX_WEIGHT &&
			width !== 0 &&
			width <= MAX_WIDTH &&
			length !== 0 &&
			length <= MAX_LENGTH
		) {
			setError(false);
			const ids: number[] = selectedServices.map(service => service.id);
			syncData(
				ids,
				locationData,
				selectedCourier,
				showInput,
				inputText,
				shipmentReducer,
				width,
				height,
				length,
				weight
			);
			dispatch(getCourierVehicleAction(preparePayload()));
			props.onChangeStep(2);
		} else {
			if (
				height > MAX_HEIGHT ||
				weight > MAX_WEIGHT ||
				width > MAX_WIDTH ||
				length > MAX_LENGTH
			) {
				setMaxDimentionError(true);
			} else {
				setMaxDimentionError(false);
			}
			let errorFeild = "";
			setErrorMsg("");
			if (selectedCourier.id === 12345) {
				errorFeild = "Verzendtype,";
			}
			if (!height) {
				errorFeild = "Hoogte,";
			}
			if (!width) {
				errorFeild = "Breedte,";
			}
			if (!weight) {
				errorFeild = "Gewicht,";
			}
			if (!length) {
				errorFeild = "lengte,";
			}
			setErrorMsg((prevState: string) => prevState + ` ${errorFeild}`);
			if (!errorFeild) {
				setError(false);
			} else {
				setError(true);
			}
		}
	};

	return (
		<Row data-cy="pageCover">
			<Col className="shipment-details-wrap" md={{ size: 12 }}>
				<Row>
					<Col md={{ size: 12 }}>
						<h2 data-cy="pageTitle" className="headingStyle">
							<FormattedMessage
								id="shipment.heading"
								defaultMessage="Always sent insured"
							/>
						</h2>
						<div className="selectShipmentTypeText">
							<FormattedMessage
								id="shipment.selectShipmentType"
								defaultMessage="Select shipment type"
							/>
						</div>
						{showInput && (
							<div className="input-courier-wrap">
								<Input
									autoFocus
									value={inputText}
									onChange={e => setInputText(e.currentTarget.value)}
									type="text"
									placeholder={describePlaceholder}
								/>
								<span onClick={() => setShowInput(false)}>X</span>
							</div>
						)}
						{!showInput && (
							<div
								data-cy="selectShipmentTypeDropdown"
								className="shipment-type-items-wrap"
							>
								{shipmentReducer.courierList
									.sort((a, b) => b.order - a.order)
									.map((courier: ICourier) => (
										<div
											onClick={() =>
												onChangeShipmentType(courier.id, courier.key)
											}
											className={`shipment-type-item  ${
												selectedCourier.id === courier.id ? "selected-item" : ""
											}`}
											key={`couriername_${courier.name}`}
										>
											{courier.name}
										</div>
									))}
								{/* <div
									onClick={() => onChangeShipmentType("9999")}
									className="shipment-type-item"
								>
									<FormattedMessage
										id="shipment.shipmentModalOtherOption"
										defaultMessage="Other"
									/>
								</div> */}
							</div>
						)}
					</Col>
				</Row>
				<Row>
					<Col className="bottom-shipment-details" md={{ size: 8 }}>
						<h2 data-cy="shipmentDetails" className="headingStyle">
							{" "}
							<FormattedMessage
								id="shipment.detail"
								defaultMessage="Estimated Dimensions of the Position"
							/>
						</h2>
						<div className="input-wrapper">
							<Row style={{ marginBottom: "9px" }}>
								<Col xs={{ size: 6 }} data-cy="heightInput">
									<ShipmentDetailsInput
										id="hoogte"
										className={height > MAX_HEIGHT ? "error" : ""}
										title="Hoogte"
										initialValue={height}
										onChangeVal={setHeight}
										description={heightDescription}
										descriptionTitle="Maximaal 500 cm."
										max={MAX_HEIGHT}
										icon="fa fa-text-height"
										formVerified={
											selectedCourier.name === "Select shipment type" &&
											!showInput
										}
									/>
								</Col>
								<Col xs={{ size: 6 }} data-cy="widthInput">
									<ShipmentDetailsInput
										id="Breedte"
										className={width > MAX_WIDTH ? "error" : ""}
										title="Breedte"
										initialValue={width}
										onChangeVal={setWidth}
										description={widthDescription}
										descriptionTitle="Maximaal 225 cm."
										max={MAX_WIDTH}
										icon="fas fa-text-width"
										formVerified={
											selectedCourier.name === "Select shipment type" &&
											!showInput
										}
									/>
								</Col>
							</Row>
							<Row>
								<Col xs={{ size: 6 }} data-cy="lengthInput">
									<ShipmentDetailsInput
										id="Lengte"
										className={length > MAX_LENGTH ? "error" : ""}
										title="Lengte"
										initialValue={length}
										onChangeVal={setLength}
										description={lengthDescription}
										descriptionTitle="Maximaal 755 cm."
										max={MAX_LENGTH}
										icon="fas fa-ruler-vertical"
										formVerified={
											selectedCourier.name === "Select shipment type" &&
											!showInput
										}
									/>
								</Col>
								<Col xs={{ size: 6 }} data-cy="weightInput">
									<ShipmentDetailsInput
										id="Gewicht"
										className={weight > MAX_WEIGHT ? "error" : ""}
										title={weightText}
										initialValue={weight}
										onChangeVal={setWeight}
										description={kgDescription}
										descriptionTitle="Maximaal 3000 kg."
										max={MAX_WEIGHT}
										icon="fas fa-weight"
										formVerified={
											selectedCourier.name === "Select shipment type" &&
											!showInput
										}
									/>
								</Col>
							</Row>
						</div>
					</Col>
					<Col md={{ size: 8 }}>
						<br />
						{error && (
							<Alert color="danger" toggle={() => setError(false)}>
								U heeft de {errorMsg.slice(0, -1)} van uw zending nog niet
								ingevuld. Wilt u dit nog doen?
							</Alert>
						)}
						{maxDimentionError && (
							<Alert color="danger" toggle={() => setMaxDimentionError(false)}>
								U heeft niet alle afmetingen naar behoren ingevuld. Controleer
								alle afmetingen en vul deze in, Let op de maximale waarde.
							</Alert>
						)}
					</Col>
				</Row>
				<Row className="action-btns-wrap mb-3 mb-md-0">
					<Col className="back-btn-col" md={{ size: 3 }}>
						<Button
							data-cy="screen2-backButton"
							className="font-weight-bold back-btn"
							onClick={event => props.onChangeStep(0)}
						>
							<FormattedMessage
								id="shipment.shipmentModalBack"
								defaultMessage="Back"
							/>
						</Button>
					</Col>
					<Col md={{ size: 3 }}>
						<span ref={submitBtnRef}>
							<Button
								data-cy="screen2-nextButton"
								className="btn-sr-yellow"
								onClick={onSubmit}
							>
								<FormattedMessage
									id="shipment.shipmentModalNext"
									defaultMessage="Next"
								/>
							</Button>
						</span>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};

export default CourierDimensionSelection;
