import React, { FC, useState } from "react";
import { Popover, PopoverBody } from "reactstrap";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormattedMessage } from "react-intl";

import "./styles/Stepper.scss";
import { IStepper } from "./Interface/props/Stepper";
import { IRootReducerState } from "common/interface/store/reducer/Reducer";
import { formatPrice } from "common/utilities";
import { BOOKING_FEE_SERVICE } from '../../common/constants'; // Import the BOOKING_FEE_SERVICE


/**
 * Sample component for Stepper
 * @param props
 * @constructor
 */
const Stepper: FC<IStepper> = props => {
	const [popoverOpen, setPopoverOpen] = useState<boolean>(false);
	const [secondPopoverOpen, setSecondPopoverOpen] = useState<boolean>(false);
	const [thirdPopoverOpen, setThirdPopoverOpen] = useState<boolean>(false);
	const [descriptionPopup1, setDescriptionPopup1] = useState<boolean | string>(
		false
	);
	const [descriptionPopup2, setDescriptionPopup2] = useState<boolean | string>(
		false
	);
	const [descriptionPopup3, setDescriptionPopup3] = useState<boolean | string>(
		false
	);

	/**
	 * Get selected services
	 */
	const selectedServices = useSelector(
		(state: IRootReducerState) => state.orderReducer.selectedServices
	);

	/**
	 * Get selected vehicle
	 */
	const selectedVehicle = useSelector((state: IRootReducerState) => {
		return state.vehiclesReducer.vehicle;
	});

	/**
	 * Get location data
	 */
	const location = useSelector((state: IRootReducerState) => {
		return state.departmentReducer.location;
	});

	/**
	 * Calculate total price
	 */
	const getPrice = () => {
		let price = 0;
		if (selectedServices) {
			selectedServices.map(
				service =>
					(price += service.service_price ? service.service_price.price : 0)
			);
		}
		if (!selectedServices.some(service => service.id === BOOKING_FEE_SERVICE.id)) {
			price += BOOKING_FEE_SERVICE.service_price.price;
		}
		return formatPrice(price);
	};

	return (
		<div className="stepper" data-cy="stepperComponent">
			<div className="stepwizard col-md-offset-3">
				<div className="stepwizard-row setup-panel">
					{props.steps &&
						props.steps.map((step, index) => {
							return (
								<div
									className="stepwizard-step"
									key={`stepwizard-step_${index}`}
								>
									<button
										id={`btn-popover-${index}`}
										onMouseOver={e => {
											if (
												props.activeStep === 2 &&
												e.currentTarget.textContent === "" &&
												(selectedServices.length ||
													location.locationName.fromInput !== "")
											) {
												setPopoverOpen(true);
											} else if (
												props.activeStep === 1 &&
												e.currentTarget.textContent === "2"
											) {
												setSecondPopoverOpen(true);
											} else if (
												(props.activeStep === 2 || props.activeStep === 1) &&
												e.currentTarget.textContent === "3"
											) {
												setThirdPopoverOpen(true);
											}
										}}
										onMouseLeave={() => {
											setPopoverOpen(false);
											setSecondPopoverOpen(false);
											setThirdPopoverOpen(false);
										}}
										onClick={() => index + 1 !== 2 && props.callBack(index + 1)}
										className={`btn ${
											props.activeStep === index + 1
												? "btn-primary"
												: props.activeStep < index + 1
												? "btn-default"
												: "btn-success"
										} btn-circle`}
									>
										{props.activeStep === index + 1 ? (
											index + 1
										) : props.activeStep < index + 1 ? (
											index + 1
										) : (
											<FontAwesomeIcon icon="check" className="check" />
										)}
									</button>
									<p className="stepper-title">
										{step.heading}{" "}
										<FontAwesomeIcon
											onMouseOver={() => {
												if (index === 0) setDescriptionPopup1(step.description);
												else if (index === 1)
													setDescriptionPopup2(step.description);
												else {
													setDescriptionPopup3(step.description);
												}
											}}
											onMouseLeave={() => {
												setDescriptionPopup1(false);
												setDescriptionPopup2(false);
												setDescriptionPopup3(false);
											}}
											id={`info-popover-${index}`}
											icon="info-circle"
											className="info-circle"
										/>
									</p>
									{/* <p className="stepper-description">{step.description}</p> */}
								</div>
							);
						})}

					<Popover
						placement="bottom"
						isOpen={!!descriptionPopup1}
						target="info-popover-0"
					>
						<PopoverBody>{descriptionPopup1}</PopoverBody>
					</Popover>
					<Popover
						placement="bottom"
						isOpen={!!descriptionPopup2}
						target="info-popover-1"
					>
						<PopoverBody>{descriptionPopup2}</PopoverBody>
					</Popover>
					<Popover
						placement="bottom"
						isOpen={!!descriptionPopup3}
						target="info-popover-2"
					>
						<PopoverBody>{descriptionPopup3}</PopoverBody>
					</Popover>

					<Popover
						placement="bottom"
						isOpen={secondPopoverOpen}
						target="btn-popover-1"
					>
						<PopoverBody>
							<FormattedMessage id="stepperManager.finishStepOneFirst" />
						</PopoverBody>
					</Popover>
					<Popover
						placement="bottom"
						isOpen={thirdPopoverOpen}
						target="btn-popover-2"
					>
						<PopoverBody>
							{" "}
							<FormattedMessage id="stepperManager.finishStepTwoFirst" />
						</PopoverBody>
					</Popover>
					<Popover
						placement="bottom"
						isOpen={popoverOpen}
						target="btn-popover-0"
					>
						<PopoverBody>
							{props.department === "vehicles" && (
								<>
									<span>
										<FormattedMessage
											id="stepperManager.registration"
											defaultMessage="Registration: "
										/>
										{selectedVehicle?.plate}
									</span>
									<br />
									{selectedVehicle?.brand.name} - {selectedVehicle?.model.name}{" "}
									(
									{selectedVehicle &&
									selectedVehicle.construction_year.length > 4
										? new Date(
												selectedVehicle.construction_year || ""
										  ).getFullYear()
										: selectedVehicle?.construction_year}
									)
								</>
							)}
							{props.department === "couriers" && (
								<>
									<div className="location-steps-wrap">
										<div className="step">
											<div>
												<div className="circle">A</div>
											</div>
											<div>
												<div style={{ color: "#000000" }} className="title">
													{location.locationName.fromInput}
												</div>
											</div>
										</div>
										<div className="step">
											<div>
												<div className="circle">B</div>
											</div>
											<div>
												<div style={{ color: "#000000" }} className="title">
													{location.locationName.toInput}
												</div>
											</div>
										</div>
									</div>
									<span>Km: {location.distance}</span>
								</>
							)}

							<br />
							<div className="selected-services-wrap">
								{selectedServices.map(service => (
									<div key={`selected-services${service.id}`}>
										{" "}
										- {service.name}
									</div>
								))}
								{/* Add booking fee if it's not already in selectedServices */}
								{!selectedServices.some(service => service.id === BOOKING_FEE_SERVICE.id) && (
									<div key={`selected-services${BOOKING_FEE_SERVICE.id}`}>
										{" "}
										- {BOOKING_FEE_SERVICE.name}
									</div>
								)}
							</div>
							<br />
							<div className="font-weight-bold total-sum">
								<FormattedMessage id="card.total" defaultMessage="From" />:{" "}
								{getPrice()}
							</div>
						</PopoverBody>
					</Popover>
				</div>
			</div>
		</div>
	);
};

export default Stepper;
