import React, { FC, useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReCAPTCHA from "react-google-recaptcha";
import { FormattedMessage, useIntl } from "react-intl";
import classnames from "classnames";
import { Spinner } from "reactstrap";
import { useHistory } from "react-router-dom";
import {
	Modal,
	ModalBody,
	FormGroup,
	Form,
	Input,
	Button,
	Row,
	Col
} from "reactstrap";
import noScroll from "no-scroll";
import "component/Modal/VehiclesModal/styles/VehiclesModal.scss";
import {
	getVehicleAction,
	getVehicleBrandsAction,
	getVehicleModelsAction,
	getVehicleFuelsAction,
	saveVehicleInformation,
	resetError,
	getVehicleYearsAction
} from "store/actions/vehicles/vehiclesActions";
import { IVehicleData } from "store/interface/vehicles/Vehicles";
import { IVehiclesModalProps } from "./Interface/props/VehiclesModalProps";
import { IRootReducerState } from "common/interface/store/reducer/Reducer";
import { SET_TOKEN } from "store/actionTypes/vehicles/vehiclesActionTypes";

/**
 * function component of VehiclesModal
 * @param props
 * @constructor
 */
const VehiclesModal: FC<IVehiclesModalProps> = ({
	licensePlate,
	onClickVehicleModal,
	queryParamVerify,
	queryParameter,
	onClose,
	getDataInitially = false,
	redirectAfterFirstPopup = false
}) => {
	const intl = useIntl();
	const history = useHistory();
	const EnterRegisterNumber = intl.formatMessage({
		id: "vehicleModal.enterRegisterNumber",
		defaultMessage: "Enter your registration number"
	});
	const IsThisYourAuto = intl.formatMessage({
		id: "vehicleModal.isThisYourAuto",
		defaultMessage: "Is this your auto"
	});
	const Yes = intl.formatMessage({
		id: "vehicleModal.yes",
		defaultMessage: "Yes"
	});
	const NotMyVehicle = intl.formatMessage({
		id: "vehicleModal.notMyVehicle",
		defaultMessage: "Not my vehicle"
	});

	const dispatch = useDispatch();
	const recaptchaRef = React.createRef<ReCAPTCHA>();
	/**
	 * Handle state for VehiclesModal
	 */
	const [vehicleNumber, setVehicleNumber] = useState<string>(
		licensePlate ? licensePlate : ""
	);

	/**
	 * Get vehicle
	 */
	const vehicleData = useSelector(
		(state: IRootReducerState) => state.vehiclesReducer
	);

	const vehicle = vehicleData.vehicle;
	const vehicleError = vehicleData.error;

	/**
	 * Check valid license plate
	 */
	const [isValidPlate, setIsValidPlate] = useState<boolean>(false);

	/**
	 * Check error for license plate
	 */
	const [isValidPlateError, setIsValidPlateError] = useState<boolean>(false);

	/**
	 * Handle Model
	 */
	const [showSecondModal, setShowSecondModal] = useState<boolean>(
		queryParamVerify
	);
	/**
	 * handle query data to render
	 */
	const [isQuery, setIsQuery] = useState<boolean>(true);

	const { currentDepartment } = useSelector(
		(state: IRootReducerState) => state.departmentReducer
	);

	const recaptchaToken = currentDepartment
		? currentDepartment.config.google_recaptcha_token
		: "";

	/**
	 * Brand State Handler
	 */
	const brands = useSelector((state: IRootReducerState) =>
		state.vehiclesReducer.selection
			? state.vehiclesReducer.selection.vehiclesBrandList
			: []
	);

	/**
	 * Model State Handler
	 */
	const models = useSelector((state: IRootReducerState) =>
		state.vehiclesReducer.selection
			? state.vehiclesReducer.selection.vehiclesModelList
			: []
	);

	/**
	 * Fuel State Handler
	 */
	const fuels = useSelector((state: IRootReducerState) =>
		state.vehiclesReducer.selection
			? state.vehiclesReducer.selection.vehiclesFuelList
			: []
	);

	const year = useSelector((state: IRootReducerState) =>
		state.vehiclesReducer.selection
			? state.vehiclesReducer.selection.vehiclesModelYears
			: []
	);

	/**
	 * BrandId State Handler
	 */
	const [selectedBrandId, setSelectedBrandId] = useState<string>("");

	/**
	 * X icon State Handler
	 */
	const [showCloseIcon, setShowCloseIcon] = useState<boolean>(true);

	/**
	 * ModelId State Handler
	 */
	const [selectedModelId, setSelectedModelId] = useState<string>("");

	/**
	 * Year State Handler
	 */
	const [selectedYear, setSelectedYear] = useState<string>("");

	/**
	 * Fuel State Handler
	 */
	const [selectedFuelId, setSelectedFuelId] = useState<string>("");

	useEffect(() => {
		noScroll.on();
		return () => {
			noScroll.off();
		};
	}, []);

	const countQueryParams = useCallback(() => {
		let count = 0;
		if (queryParameter) {
			Object.keys(queryParameter).forEach((item: string) => {
				if (Boolean(queryParameter[item])) count++;
			});
		}
		return count;
	}, [queryParameter]);

	/**
	 * render data using query parameter
	 * @param key
	 */
	const renderQueryData = (key: string) => {
		const DATA =
			key === "brand"
				? brands
				: key === "model"
				? models
				: key === "fuel"
				? fuels
				: year;
		let Options: string = "";
		if (key === "brand" || key === "fuel") {
			Options =
				queryParameter[key].charAt(0).toUpperCase() +
				queryParameter[key].slice(1);
		} else if (key === "model") {
			Options = queryParameter[key].toUpperCase();
		}
		const fetchData =
			key === "year"
				? DATA?.find(item => item.toString() === queryParameter[key])
				: DATA?.find(item => item.name === Options);
		if (key === "brand") {
			if (fetchData) {
				setSelectedBrandId(fetchData.id.toString());
				dispatch(getVehicleModelsAction(fetchData.id));
			} else {
				setSelectedBrandId("null");
			}
		}
		if (key === "model") {
			if (fetchData) {
				setSelectedModelId(fetchData.id.toString());
				dispatch(getVehicleYearsAction(fetchData.id));
				dispatch(getVehicleFuelsAction(+selectedBrandId, fetchData.id));
			} else {
				setSelectedModelId("null");
			}
		}
		if (key === "year") {
			// if (fetchData) {
			setSelectedYear(queryParameter[key]);
			// } else {
			// 	setSelectedYear("null");
			// }
		}
		if (key === "fuel") {
			if (fetchData) {
				setSelectedFuelId(fetchData.id.toString());
			} else {
				setSelectedFuelId("null");
			}
		}
	};

	/**
	 * render brand
	 */
	useEffect(() => {
		if (
			countQueryParams() &&
			queryParameter.brand &&
			brands?.length &&
			!selectedBrandId &&
			isQuery
		) {
			renderQueryData("brand");
		}
	}, [brands, selectedBrandId]); // eslint-disable-line react-hooks/exhaustive-deps

	/**
	 * render model
	 */
	useEffect(() => {
		if (
			countQueryParams() &&
			queryParameter.model &&
			(models?.length || selectedBrandId === "null") &&
			!selectedModelId &&
			isQuery
		) {
			renderQueryData("model");
		}
	}, [models, selectedModelId, selectedBrandId]); // eslint-disable-line react-hooks/exhaustive-deps

	/**
	 * render year
	 */
	useEffect(() => {
		if (
			countQueryParams() &&
			queryParameter.year &&
			(year?.length || selectedModelId === "null") &&
			!selectedYear &&
			isQuery
		) {
			renderQueryData("year");
		}
	}, [year, selectedYear, selectedModelId]); // eslint-disable-line react-hooks/exhaustive-deps

	/**
	 * render fuel
	 */
	useEffect(() => {
		if (
			countQueryParams() &&
			queryParameter.fuel &&
			(fuels?.length || selectedModelId === "null") &&
			!selectedFuelId &&
			isQuery
		) {
			renderQueryData("fuel");
		}
	}, [fuels, selectedFuelId, selectedModelId]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(
		() => {
			if (
				queryParamVerify &&
				selectedBrandId &&
				selectedModelId &&
				selectedYear &&
				selectedFuelId &&
				!vehicle &&
				isQuery
			) {
				saveData();
			}
		}, // eslint-disable-next-line react-hooks/exhaustive-deps
		[
			selectedBrandId,
			selectedModelId,
			selectedYear,
			selectedFuelId,
			queryParamVerify,
			isQuery
		]
	);

	useEffect(() => {
		if (!vehicle) {
			dispatch(getVehicleBrandsAction());
		}
	}, [dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

	const getVehicleData = useCallback((vehicleNumber: string) => {
		dispatch(getVehicleAction(vehicleNumber));
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const selectChanged = (event: any, field: string) => {
		switch (field) {
			case "brand": {
				setSelectedBrandId(event.target.value);
				setSelectedModelId("");
				setSelectedFuelId("");
				setSelectedYear("");
				dispatch(getVehicleModelsAction(+event.target.value));
				break;
			}
			case "model": {
				setSelectedModelId(event.target.value);
				setSelectedFuelId("");
				setSelectedYear("");
				dispatch(getVehicleYearsAction(+event.target.value));
				dispatch(getVehicleFuelsAction(+selectedBrandId, +event.target.value));
				break;
			}
			case "fuel": {
				setSelectedFuelId(event.target.value);
				break;
			}
			case "year": {
				setSelectedYear(event.target.value);
				break;
			}
		}
	};

	const vehicleConfirm = async () => {
		await recaptchaRef.current?.execute();
		onClickVehicleModal();
	};

	const saveData = () => {
		dispatch(
			saveVehicleInformation(
				+selectedBrandId,
				+selectedModelId,
				+selectedFuelId,
				+selectedYear,
				vehicleNumber
			)
		);
		setShowSecondModal(true);
	};

	useEffect(() => {
		if (
			vehicleNumber.split("-").join("").length >= 6 &&
			vehicleNumber.split("-").join("") !== vehicle?.plate
		) {
			setShowCloseIcon(false);
			// if (!queryParameter["brand"]) {
			getVehicleData(vehicleNumber);
			// }
		}
		if (licensePlate && getDataInitially) setShowSecondModal(true);
	}, [getDataInitially]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const onKeyDown = ({ key }: { key: string }) => {
			if (key === "Enter") {
				onClickFirstPopupSubmit();
			}
		};
		document.addEventListener("keydown", onKeyDown);
		return () => {
			document.removeEventListener("keydown", onKeyDown);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isValidPlate]);

	const onChangeVehicleNumber = (e: any) => {
		setVehicleNumber(e.target.value);
		if (e.target.value.split("-").join("").length >= 6) {
			setShowCloseIcon(false);
			getVehicleData(e.target.value);
		}
	};

	useEffect(() => {
		if (
			vehicleNumber.split("-").join("").length >= 6 &&
			vehicle &&
			(vehicleNumber === vehicle.formatted_plate ||
				vehicleNumber === vehicle.plate)
		) {
			setIsValidPlate(true);
			setIsValidPlateError(false);
		} else {
			setIsValidPlate(false);
		}
	}, [vehicleNumber, vehicle]);

	useEffect(() => {
		if (vehicle) {
			setVehicleNumber(vehicle.formatted_plate || "");
		}
	}, [vehicle]);

	const resetVehicleData = () => {
		setSelectedBrandId("");
		setSelectedModelId("");
		setSelectedFuelId("");
		setSelectedYear("");
		setShowSecondModal(false);
		setIsQuery(false);
		if (vehicleError) {
			dispatch(resetError());
		}
	};

	useEffect(() => {
		if (
			!vehicle?.plate &&
			vehicle?.brand &&
			vehicle.construction_year &&
			vehicle.fuel &&
			vehicle.model &&
			year.length &&
			fuels &&
			models &&
			brands
		) {
			setSelectedBrandId((vehicle.brand.id as unknown) as string);
			setSelectedModelId((vehicle.model.id as unknown) as string);
			setSelectedFuelId((vehicle.fuel.id as unknown) as string);
			setSelectedYear((vehicle.construction_year as unknown) as string);
		}
	}, [vehicle]);

	const onClickFirstPopupSubmit = () => {
		if (isValidPlate) {
			setIsValidPlateError(false);
			if (redirectAfterFirstPopup) {
				history.push(
					`/calculate?license-plate=${vehicleNumber.split("-").join("")}`
				);
			} else {
				setShowSecondModal(true);
			}
		}
	};

	// const removeVehicle = async () => {
	//   await dispatch(removeVehicleDataAction());
	// };

	/**
	 * Generate Options for Select boxes
	 * @param data (array of list)
	 * @param field (field name)
	 */
	const generateOptions = (data: any[], field: string) => {
		return data.map((item: IVehicleData, index: number) => {
			return (
				<option className="cy-options" key={field + index} value={item.id}>
					{item.name}
				</option>
			);
		});
	};

	const compareSort = (a: any, b: any) => {
		if (a.name < b.name) {
			return -1;
		}
		if (a.name > b.name) {
			return 1;
		}
		return 0;
	};

	const brandOption = brands
		? generateOptions(brands.sort(compareSort), "brand")
		: [];
	const modelOption = models
		? generateOptions(models.sort(compareSort), "model")
		: [];
	const fuelOption = fuels
		? generateOptions(fuels.sort(compareSort), "fuel")
		: [];
	const yearOption = year
		? year.map((year: number) => (
				<option key={"year" + year} value={year}>
					{year}
				</option>
		  ))
		: [];

	const formVerified: boolean =
		Boolean(selectedBrandId) &&
		Boolean(selectedModelId) &&
		Boolean(selectedYear) &&
		Boolean(selectedFuelId);

	const inputForm =
		!showSecondModal && !vehicleError ? (
			<div>
				<Row className="myRow">
					<Input
						type="text"
						onKeyPress={e => {
							if (
								e.key === "Enter" &&
								vehicleNumber.split("-").join("").length < 6
							) {
								setIsValidPlateError(true);
								e.preventDefault();
							}
							if (
								e.key === "Enter" &&
								vehicleNumber.split("-").join("").length >= 6 &&
								isValidPlate
							) {
								setShowSecondModal(true);
							}
						}}
						name="vehicleNumber"
						className={`vehicleNumberStyle ${
							isValidPlateError ? "is-invalid" : ""
						}`}
						maxLength={8}
						value={vehicleNumber}
						autoComplete="off"
						placeholder={EnterRegisterNumber}
						onChange={onChangeVehicleNumber}
					/>
					<div
						onClick={onClickFirstPopupSubmit}
						className={classnames("submitVehicleNumber", "btnCat", {
							isValid: isValidPlate
						})}
					>
						{vehicleData.loading ? (
							<Spinner animation="border" />
						) : (
							<FontAwesomeIcon
								icon="caret-right"
								className="caret-right"
								size="2x"
							/>
						)}
					</div>
					{isValidPlateError ? (
						<div style={{ color: "red" }}>license plate is invalid.</div>
					) : (
						""
					)}
				</Row>
				<Row className="no-gutters d-flex align-items-center">
					<Col sm="5" xs="5">
						<hr color="black" className="my-2" />
					</Col>
					<Col sm="2" xs="2" className="orText text-center">
						<p className="mb-0">
							<FormattedMessage id="vehicleModal.or" defaultMessage="OR" />
						</p>
					</Col>
					<Col sm="5" xs="5">
						<hr color="black" className="my-2" />
					</Col>
				</Row>
				<h3 className="mb-0 text-center title p-3">
					<FormattedMessage
						id="vehicleModal.selectionText"
						defaultMessage="Select the make, model, year and fuel of the respective vehicle
          car"
					/>
				</h3>
				<Col
					className="select-car pt-3 pb-3"
					sm="12"
					md={{ size: 10, offset: 1 }}
				>
					<div>
						<Input
							type="select"
							className="selectBox"
							value={selectedBrandId}
							disabled={!Boolean(brands?.length)}
							onChange={event => selectChanged(event, "brand")}
							id="selectMake"
						>
							<FormattedMessage
								id="vehicleModal.selectMake"
								defaultMessage="Select Make"
							>
								{message => (
									<option disabled value="">
										{message}
									</option>
								)}
							</FormattedMessage>
							{brandOption}
						</Input>
						<Input
							type="select"
							className="selectBox"
							value={selectedModelId}
							disabled={selectedBrandId === "" || !Boolean(models?.length)}
							onChange={event => selectChanged(event, "model")}
							id="selectModel"
						>
							<FormattedMessage
								id="vehicleModal.selectModel"
								defaultMessage="Select Model"
							>
								{message => <option value="">{message}</option>}
							</FormattedMessage>
							{modelOption}
						</Input>
						<Input
							type="select"
							className="selectBox"
							value={selectedYear}
							disabled={
								selectedBrandId === "" ||
								selectedModelId === "" ||
								!Boolean(year?.length)
							}
							onChange={event => selectChanged(event, "year")}
							id="selectYear"
						>
							<FormattedMessage
								id="vehicleModal.selectYear"
								defaultMessage="Select Year"
							>
								{message => <option value="">{message}</option>}
							</FormattedMessage>
							{yearOption}
						</Input>
						<Input
							type="select"
							className="selectBox"
							value={selectedFuelId}
							disabled={
								selectedBrandId === "" ||
								selectedModelId === "" ||
								!Boolean(fuels?.length)
							}
							onChange={event => selectChanged(event, "fuel")}
							id="selectFuel"
						>
							<FormattedMessage
								id="vehicleModal.selectFuel"
								defaultMessage="Select Fuel"
							>
								{message => <option value="">{message}</option>}
							</FormattedMessage>
							{fuelOption}
						</Input>
						{formVerified && (
							<Button
								className={
									formVerified
										? "selectButton btn-sr-yellow"
										: "btnDisabled btn-sr-yellow"
								}
								disabled={!formVerified}
								type="submit"
								onClick={saveData}
							>
								<FormattedMessage
									id="vehicleModal.nextStep"
									defaultMessage="Next step"
								/>
							</Button>
						)}
					</div>
				</Col>
			</div>
		) : null;

	const vehicleDisplay =
		!vehicleError && showSecondModal ? (
			<div className="itemDetail">
				{vehicle ? (
					<>
						<h2 className="title">
							{vehicle.brand.name} {vehicle.model.name}{" "}
							{(vehicle.fuel && `${vehicle.fuel.name} `) || ""}
							{vehicle.construction_year.length > 4
								? new Date(vehicle.construction_year).getFullYear()
								: vehicle.construction_year}
						</h2>
						<img
							className="imageClass"
							src={
								vehicle.images?.length
									? vehicle?.images[0].location
									: "no_image.jpg"
							}
							alt={"Please Wait"}
						/>
						<div className="questionStyle">{IsThisYourAuto}</div>
						<Row sm="2">
							<Col>
								<Button
									className="btnNo btn-sr-yellow"
									onClick={resetVehicleData}
									color="link"
								>
									{NotMyVehicle}
								</Button>
							</Col>
							<Col>
								<Button
									className="btnYes btn-sr-yellow"
									onClick={vehicleConfirm}
									color="link"
								>
									{Yes}
								</Button>
							</Col>
						</Row>
						<ReCAPTCHA
							ref={recaptchaRef}
							sitekey={recaptchaToken}
							size="invisible"
							onChange={token =>
								dispatch({
									type: SET_TOKEN,
									payload: token
								})
							}
							onErrored={() => console.log("Fail")}
						/>
					</>
				) : (
					<Row>
						<Col sm="12" md={{ size: 2, offset: 5 }}>
							<Spinner
								style={{ width: "3rem", height: "3rem" }}
								color="success"
							/>
						</Col>
					</Row>
				)}
			</div>
		) : null;

	const displayError = vehicleError ? (
		<Row>
			<Col sm={{ size: 12 }}>
				<p style={{ fontWeight: "bold" }} className="orText text-center">
					{vehicleError}
					<img alt="Go back" width="30px" height="30px" src="not-found.png" />
				</p>
				<Button
					className="btn-secondary go-back-btn"
					onClick={resetVehicleData}
					color="link"
				>
					Go Back
				</Button>
			</Col>
		</Row>
	) : null;

	return (
		<Modal isOpen={true} className="vehicle-modal" size="md">
			<Row>
				<Col sm="12" md={{ size: 10, offset: 1 }}>
					<ModalBody>
						<div className="title text-center cy-locationPage">
							{showSecondModal ? (
								""
							) : (
								<h2>
									<FormattedMessage
										id="shipment.shipmentModalHeading"
										defaultMessage="Next Step"
									/>
								</h2>
							)}
						</div>
						<Form>
							<FormGroup>
								{inputForm ? (
									inputForm
								) : vehicleDisplay ? (
									vehicleDisplay
								) : displayError ? (
									displayError
								) : (
									<Row>
										<Col sm="12" md={{ size: 2, offset: 5 }}>
											<Spinner
												style={{ width: "3rem", height: "3rem" }}
												color="success"
											/>
										</Col>
									</Row>
								)}
							</FormGroup>
						</Form>
					</ModalBody>
					{onClose && isValidPlate && showCloseIcon ? (
						<span onClick={onClose} className="modal-close-icon">
							X
						</span>
					) : (
						""
					)}
				</Col>
				{!vehicleError && !showSecondModal ? (
					<img
						className="peter-noLicense"
						alt=""
						src="https://vandaag-autos.nl/wp-content/themes/serviceright-theme/assets/img/peter.png"
					/>
				) : null}
			</Row>
		</Modal>
	);
};

export default VehiclesModal;
