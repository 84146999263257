import React from "react";
import { Spinner } from "reactstrap";

import "./Spinner.scss";

const SrSpinner = () => {
	return (
		<div className="spinner">
			<Spinner style={{ width: "3rem", height: "3rem" }} color="success" />
			<p>Loading...</p>
		</div>
	);
};

export default SrSpinner;
