import React, { FC, useEffect, useState } from "react";
import Helmet from "react-helmet";
import moment from "moment";
import Echo from "laravel-echo";
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Redirect
} from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";

import "common/styles/global.scss";
import PrivateRoute from "./PrivateRoute/PrivateRoute";
import PublicRoute from "./PublicRoute/PublicRoute";
import Unsubscribe from "views/Unsubscribe";
import UnsubscribeApk from "views/UnsubscribeApk";
import MasterLayout from "component/Layout/MasterLayout";
import PageNotFound from "views/PageNotFound";
import StepperManager from "views/StepperManager";
import Confirm from "views/Confirm";
import ConfirmEmail from "views/Order/ConfirmEmail";
import HaveSelectedServicesRoute from "./HaveSelectedServicesRoute";
import Notification from "component/Notification/Notification";
import SrSpinner from "component/Spinner/Spinner";
import ServiceCodePopup from "component/ServiceCodePopup/ServiceCodePopup";
import Feedback from "component/Feedback";
import { getCurrentDepartmentAction } from "store/actions/department/deparmentActions";
import { IRootReducerState } from "common/interface/store/reducer/Reducer";
import { idDevEnv } from "common/utilities/auth/auth";
import { getOrderAction } from "store/actions/order/orderActions";
import { getVehicleServicesAction } from "store/actions/vehicles/vehiclesActions";
import { GET_SYNCHRONIZED_DATA } from "store/actionTypes/order/orderActionTypes";
import ComingSoon from "views/ComingSoon";
import WinInvoice from "views/WinInvoice";
import PayInvoice from "component/PayInvoice";
import PayOrder from "component/PayOrder";

interface INotification {
	title: string;
	description: string;
	image: string;
	created_at: string;
}

interface IWsOrdersResponse {
	title: string;
	description: string;
	image: string;
	created_at: string;
}

const basename = window.location.pathname.startsWith("/app") ? "/app" : "";

/**
 * Register applications all routes here
 * @constructor
 */
const Routes: FC = () => {
	const [notification, setShownotification] = useState<boolean>(false);
	const [notificationData, setsNotificationData] = useState<INotification>({
		title: "",
		description: "",
		image: "",
		created_at: ""
	});
	const isPriceNotNeeded =
		window.location.pathname !== "/" &&
		window.location.pathname !== basename &&
		window.location.pathname !== `${basename}/calculate` &&
		window.location.pathname !== `${basename}/order`;

	const showNotification = () => {
		setShownotification(true);
	};

	const closeNotification = () => {
		setShownotification(false);
	};

	/**
	 * Handle loading state
	 */
	const [isLoading, setLoading] = useState<boolean>(true);

	/**
	 * Register dispatch for actions
	 */
	const dispatch = useDispatch();

	/**
	 * Get current couriers
	 */
	const currentDepartment = useSelector(
		(state: IRootReducerState) => state.departmentReducer.currentDepartment
	);

	/**
	 * Get error
	 */
	const error = useSelector(
		(state: IRootReducerState) => state.departmentReducer.error
	);

	/**
	 * Get current couriers
	 */
	const syncedData = useSelector(
		(state: IRootReducerState) => state.orderReducer.syncedData
	);

	/**
	 * Get services
	 */
	const services = useSelector(
		(state: IRootReducerState) => state.vehiclesReducer.services
	);

	// useEffect(() => {
	// 	// @ts-ignore
	// 	const Tawk_API = window.Tawk_API || {};
	// 	Tawk_API.onChatStarted = function() {
	// 		if (currentDepartment) {
	// 			getServiceCode(getCredentials(currentDepartment?.slug)).then(res => {
	// 				Tawk_API.setAttributes({
	// 					support_code: res.code.toString()
	// 				});
	// 			});
	// 		}
	// 	};
	// }, [currentDepartment]);

	useEffect(() => {
		const selectedDepartment = localStorage.getItem("selectedDepartment");
		dispatch(
			getCurrentDepartmentAction(
				idDevEnv()
					? selectedDepartment
						? selectedDepartment
						// : "beauty"
						// : "vehicles"
						: "couriers"
					: undefined
			)
		);
	}, [dispatch]);

	useEffect(() => {
		if (currentDepartment) {
			const options = {
				broadcaster: "pusher",
				key: currentDepartment.slug,
				wsHost: process.env.REACT_APP_WEBSOCKET_BASE_URL,
				wssPort: 6001,
				disableStats: true,
				forceTLS: true
				// enabledTransports: ["wss"]
			};

			const echo = new Echo(options);

			echo
				.channel("orders")
				.listen(".orders.created", (data: IWsOrdersResponse) => {
					setsNotificationData({
						...notificationData,
						title: data.title,
						description: data.description,
						image: data.image,
						created_at: data.created_at
					});
					showNotification();
				});
		}
	}, [currentDepartment, setsNotificationData, notificationData]);

	/**
	 * get vehicle services. For now we show vehicle services for couriers too until courior services api is ready.
	 */
	useEffect(() => {
		if (currentDepartment) {
			if (localStorage.getItem(`${currentDepartment.slug}-credentials`)) {
				dispatch(getOrderAction(currentDepartment.slug));
			} else {
				dispatch({
					type: GET_SYNCHRONIZED_DATA,
					payload: "Known error occured"
				});
			}
		}
	}, [dispatch, currentDepartment]);

	useEffect(() => {
		const currentPage = window.location.pathname.split("/")[
			window.location.pathname.split("/").length - 1
		];
		if (
			syncedData &&
			(window.location.pathname === "/" ||
				(basename && window.location.pathname === basename) ||
				currentPage === "calculate" ||
				currentPage === "order")
		) {
			dispatch(getVehicleServicesAction());
		}
	}, [dispatch, syncedData]);

	useEffect(() => {
		if (currentDepartment && syncedData && (services || isPriceNotNeeded)) {
			setLoading(false);
		}
	}, [currentDepartment, syncedData, services, isPriceNotNeeded]);

	const getTitle = () => {
		if (currentDepartment) {
			return currentDepartment.slug + "Department.title";
		}
	};

	const renderTitle = (
		<FormattedMessage id={getTitle()} defaultMessage="Vandaag">
			{title => (
				<Helmet>
					<link rel="icon" href={currentDepartment?.image.favicon} />
					<title>{title}</title>
				</Helmet>
			)}
		</FormattedMessage>
	);

	return !isLoading && currentDepartment && (services || isPriceNotNeeded) ? (
		<Router basename={basename}>
			<Notification
				toggle={closeNotification}
				title={notificationData.title}
				time={moment(notificationData.created_at).fromNow()}
				description={notificationData.description}
				image={notificationData.image}
				show={notification}
				type="ws-notification"
			/>
			{renderTitle}
			<MasterLayout currentDepartment={currentDepartment}>
				<Switch>
					<PublicRoute exact path="/calculate" component={StepperManager} />
					<PublicRoute
						exact
						path={`/orders/:id/win-uw-factuur-bedrag`}
						component={WinInvoice}
					/>
					<PublicRoute
						exact
						path="/503"
						component={() => <ServiceCodePopup isOpenServiceCodePopUp={true} />}
					/>
					<HaveSelectedServicesRoute
						syncedSelectedServices={syncedData.selected_services}
						exact
						path="/order"
						component={StepperManager}
					/>
					<PublicRoute exact path="/orders/:orderId" component={Confirm} />
					<PublicRoute
						exact
						path="/orders/:orderId/quote"
						component={Confirm}
					/>
					<PublicRoute
						exact
						path="/orders/:orderId/confirm-email"
						component={ConfirmEmail}/>
					<PublicRoute exact path="/feedbacks/:id?:hash" component={Feedback} />
					<PublicRoute
						exact
						path="/payment-link/invoices"
						component={PayInvoice}
					/>
					<PublicRoute exact path="/orders/:orderId/pay" component={PayOrder} />
					<PublicRoute exact path="/unsb-apk" component={UnsubscribeApk} />
					<PublicRoute
						exact
						path="/newsletters/cancel-subscription"
						component={Unsubscribe}
					/>
					<Route exact path="/">
						<Redirect to="/calculate" />
					</Route>
					<PublicRoute exact path="/coming-soon" component={ComingSoon} />
					<PrivateRoute exact path="/*" component={PageNotFound} />
				</Switch>
			</MasterLayout>
		</Router>
	) : error ? (
		<ServiceCodePopup isOpenServiceCodePopUp={true} />
	) : (
		<SrSpinner />
	);
};

export default Routes;
