/*global google*/

import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormGroup, Button, Row, Col, Input, Alert } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhoneAlt } from "@fortawesome/free-solid-svg-icons";
import { FormattedMessage, useIntl } from "react-intl";
import PlacesAutocomplete, {
	geocodeByAddress,
	getLatLng
} from "react-places-autocomplete";

import "./LocationInput.scss";
import { setCourierLocations } from "store/actions/department/deparmentActions";
import { ILocationInputProps } from "./Interface/props/LocationInputProps";
import { IRootReducerState } from "../../common/interface/store/reducer/Reducer";
import ServiceCodePopup from "component/ServiceCodePopup/ServiceCodePopup";
import { useIsShowSupportPhone } from "util/customHooks";

const LocationInput: FC<ILocationInputProps> = props => {
	/**
	 * Dispatch action
	 */
	const dispatch = useDispatch();

	/**
	 * Handle state for CouriersModal
	 */
	const [fromInput, setFromInput] = useState<string>("");
	const [toInput, setToInput] = useState<string>("");
	const [fromInputLatLng, setFromInputLatLng] = useState({});
	const [toInputLatLng, setToInputLatLng] = useState({});
	const [haveFromError, setHaveFromError] = useState(false);
	const [haveToError, setHaveToError] = useState(false);
	const [clearButton1, setClearButton1] = useState(false);
	const [clearButton2, setClearButton2] = useState(false);
	const [locationError, setLocationError] = useState(false);
	const [isServiceCodePopupOpen, setIsServiceCodePopupOpen] = useState(false);
	const [supportPhone, setSupportPhone] = useState<string>("");
	const isShowSupportPhone = useIsShowSupportPhone();
	const intl = useIntl();

	const directionsService = new google.maps.DirectionsService();

	/**
	 * Get shipment state
	 */
	const location = useSelector(
		(state: IRootReducerState) => state.departmentReducer.location
	);
	/**
	 * Get current department state
	 */
	const currentDepartment = useSelector(
		(state: IRootReducerState) => state.departmentReducer.currentDepartment
	);

	useEffect(() => {
		if (location) {
			if (location.locationName) {
				setFromInput(location.locationName.fromInput);
				setToInput(location.locationName.toInput);
			}
			if (location.locationsLatLng.length) {
				setFromInputLatLng(location.locationsLatLng[0]);
				setToInputLatLng(location.locationsLatLng[1]);
			}
		}
	}, [location]);

	useEffect(() => {
		if (currentDepartment && currentDepartment.support_phone) {
			setSupportPhone(currentDepartment.support_phone);
		}
	}, [currentDepartment]);

	useEffect(() => {
		if ("redirect" in location && fromInputLatLng && toInputLatLng) {
			onSetLocation(1);
		}
	}, [fromInputLatLng, toInputLatLng]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (fromInput) {
			setClearButton1(true);
			setLocationError(false);
		} else {
			setClearButton1(false);
		}
	}, [fromInput]);

	useEffect(() => {
		if (toInput) {
			setClearButton2(true);
			setLocationError(false);
		} else {
			setClearButton2(false);
		}
	}, [toInput]);

	const onClickClear = (location: boolean) => {
		location ? fromHandleChange("") : toHandleChange("");
	};

	const contains = (target: any, pattern: any) => {
		let value = 0;
		pattern.forEach(function(word: any) {
			value += target.includes(word);
		});
		return value === 1;
	};

	const boldQuery = (str: string, query: string) => {
		const n = str.toUpperCase();
		const q = query.toUpperCase();
		const x = n.indexOf(q);
		if (!q || x === -1) {
			return str; // bail early
		}
		const l = q.length;
		return (
			str.substr(0, x) + "<b>" + str.substr(x, l) + "</b>" + str.substr(x + l)
		);
	};

	const toggleError = () => {
		setHaveToError(false);
		setHaveFromError(false);
	};

	const renderFunc = (
		{ getInputProps, getSuggestionItemProps, suggestions, loading }: any,
		placeholder: string,
		inputText: string,
		isNotValid: boolean
	) => {
		return (
			<div>
				<Input
					className={isNotValid ? "is-invalid" : ""}
					{...getInputProps({ placeholder })}
				/>

				{suggestions.length ? (
					<div className="Demo__autocomplete-container">
						{loading && <div style={{ padding: "0 10px" }}>Loading...</div>}
						{suggestions
							.filter((suggestion: any) => {
								if (
									suggestion.description === fromInput ||
									suggestion.description === toInput
								) {
									return false;
								}
								const data = ["country", "locality"];
								return !contains(suggestion.types, data);
							})
							.map((suggestion: any) => {
								const className = classnames("Demo__suggestion-item", {
									"Demo__suggestion-item--active": suggestion.active
								});
								return (
									<div
										{...getSuggestionItemProps(suggestion, {
											className
										})}
									>
										<i
											style={{ marginRight: "6px", color: "#868686" }}
											className="fas fa-map-marker-alt"
											aria-hidden="true"
										></i>
										<span
											className="cy-autoSuggestionList"
											dangerouslySetInnerHTML={{
												__html: boldQuery(suggestion.description, inputText)
											}}
										></span>
									</div>
								);
							})}
					</div>
				) : (
					""
				)}
			</div>
		);
	};

	/**
	 * from change handler
	 * @param address
	 */
	const fromHandleChange = (address: string) => {
		setFromInputLatLng({});
		setFromInput(address);
	};

	/**
	 * to change handler
	 * @param address
	 */
	const toHandleChange = (address: string) => {
		setToInputLatLng({});
		setToInput(address);
	};

	const formVerified: boolean = Boolean(fromInput) && Boolean(toInput);

	/**
	 * from select handler
	 * @param address
	 */
	const fromHandleSelect = (address: any, placeId: string) => {
		selectHandler(address, "from", placeId);
	};

	/**
	 * Google Maps API error handler
	 * @param status
	 * @param clearSuggestions
	 */
	const OnMapError = (status: any, clearSuggestions: any) => {
		clearSuggestions();
	};

	/**
	 * to select handler
	 * @param address
	 */
	const toHandleSelect = (address: any, placeId: string) => {
		selectHandler(address, "to", placeId);
	};

	/**
	 * common select handler
	 * @param address
	 * @param field
	 */
	const selectHandler = (address: any, field: string, placeId: string) => {
		field === "from" ? setFromInput(address) : setToInput(address);
		geocodeByAddress(address)
			.then(results => getLatLng(results[0]))
			.then(latLng => {
				field === "from"
					? setFromInputLatLng({
							latitude: latLng.lat,
							longitude: latLng.lng,
							placeId
					  })
					: setToInputLatLng({
							latitude: latLng.lat,
							longitude: latLng.lng,
							placeId
					  });
			})
			.catch(error => console.error("Error", error));
	};

	/**
	 * set location
	 */
	const onSetLocation = (index: number) => {
		if (
			Object.keys(fromInputLatLng).length &&
			Object.keys(toInputLatLng).length
		) {
			const locationData = {
				locationName: {
					fromInput,
					toInput
				},
				locationsLatLng: [fromInputLatLng, toInputLatLng],
				distance: ""
			};

			const route = {
				origin: fromInput,
				destination: toInput,
				travelMode: google.maps.TravelMode.DRIVING
			};

			directionsService.route(route, function(response: any, status: any) {
				if (status === "OK" && response?.routes[0]?.legs[0]) {
					locationData.distance = response?.routes[0]?.legs[0].distance.text;
					dispatch(setCourierLocations(locationData));
					props.onChangeStep(index);
				} else if (status === "ZERO_RESULTS") {
					setLocationError(true);
				}
			});
		} else {
			if (fromInput.length === 0) {
				setHaveFromError(true);
			} else {
				setHaveFromError(false);
			}
			if (toInput.length === 0) {
				setHaveToError(true);
			} else {
				setHaveToError(false);
			}
		}
	};

	/**
	 * set state on props change
	 */
	useEffect(() => {
		if (localStorage.getItem("locationData") as string) {
			const { locationName, locationsLatLng } = JSON.parse(
				localStorage.getItem("locationData") as string
			);
			setFromInputLatLng(locationsLatLng[0]);
			setToInputLatLng(locationsLatLng[1]);
			setFromInput(locationName.fromInput);
			setToInput(locationName.toInput);
		}
	}, [props.isOpen]);

	const onClickChat = () => {
		// @ts-ignore
		Freshbots.showWidget("open");
	};

	const searchOptions = {
		types: ["address"]
	};

	const toggleServiceCodePopup = () =>
		setIsServiceCodePopupOpen(!isServiceCodePopupOpen);

	return (
		<div>
			<div className="title cy-locationPage">
				<h2>
					<FormattedMessage
						id="shipment.shipmentModalHeading"
						defaultMessage="Next Step"
					/>
				</h2>
			</div>
			<Row className="cy-fromToPageBody">
				<Col md={{ size: 8, offset: 2 }}>
					<Row className="text-box">
						<Col md={{ size: 12 }}>
							<FormGroup>
								<div className="embed-submit-field" id="cy-inputOne">
									<PlacesAutocomplete
										value={fromInput}
										onChange={fromHandleChange}
										onSelect={fromHandleSelect}
										onError={OnMapError}
										searchOptions={searchOptions}
									>
										{e =>
											renderFunc(
												e,
												intl.formatMessage({ id: "customMap.from" }),
												fromInput,
												haveFromError && !formVerified
											)
										}
									</PlacesAutocomplete>
									{clearButton1 && (
										<button
											type="button"
											className="close-icon"
											onClick={() => onClickClear(true)}
										>
											X
										</button>
									)}
								</div>
							</FormGroup>
						</Col>
						<Col md={{ size: 12 }}>
							<FormGroup>
								<div className="embed-submit-field" id="cy-inputTwo">
									<PlacesAutocomplete
										value={toInput}
										onChange={toHandleChange}
										onSelect={toHandleSelect}
										onError={OnMapError}
										searchOptions={searchOptions}
									>
										{e =>
											renderFunc(
												e,
												intl.formatMessage({ id: "customMap.to" }),
												toInput,
												haveToError && !formVerified
											)
										}
									</PlacesAutocomplete>
									{clearButton2 && (
										<button
											type="button"
											className="close-icon"
											onClick={() => onClickClear(false)}
										>
											X
										</button>
									)}
								</div>
							</FormGroup>
						</Col>
					</Row>
					{!formVerified && (haveToError || haveFromError) && (
						<Row>
							<Col className="error-wrap" md={12}>
								<Alert
									color="danger"
									isOpen={haveFromError}
									toggle={() => setHaveFromError(false)}
								>
									<FormattedMessage
										id="shipment.fromLocationError"
										defaultMessage="You have not yet entered the pick-up location. Do you still want to do this?"
									/>
								</Alert>
								<Alert color="danger" isOpen={haveToError} toggle={toggleError}>
									<FormattedMessage
										id="shipment.toLocationError"
										defaultMessage="You have not yet entered the delivery location. Do you still want to do this?"
									/>
								</Alert>
							</Col>
						</Row>
					)}
					{locationError && (
						<Row>
							<Col className="error-wrap" md={12}>
								<Alert
									color="danger"
									isOpen={locationError}
									toggle={() => setLocationError(false)}
								>
									<FormattedMessage id="couriers.locationError1" />{" "}
									<a href="tel:088 - 2222 001">
										<FormattedMessage id="couriers.locationError2" />{" "}
									</a>
									<FormattedMessage id="couriers.locationError3" />{" "}
									<a href="javascript:;" onClick={onClickChat}>
										<FormattedMessage id="couriers.locationError4" />
									</a>{" "}
									<FormattedMessage id="couriers.locationError5" />
								</Alert>
							</Col>
						</Row>
					)}
					<div className="btn-go-wrap">
						<Button
							data-cy="screen1-NextButton"
							type="submit"
							className={
								formVerified ? "btn-sr-yellow" : "btn-sr-yellow btnDisabled"
							}
							size="sm"
							block
							onClick={() => onSetLocation(1)}
							// disabled={!formVerified}
						>
							<FormattedMessage
								id="shipment.shipmentModalNext"
								defaultMessage="Next Step"
							/>
						</Button>

						<Row className="divider-row">
							<Col>
								<hr color="black" />
							</Col>
							<div className="d-flex justify-content-center align-items-center">
								<p className="of mb-0">OF</p>
							</div>
							<Col>
								<hr color="black" />
							</Col>
						</Row>

						<div className="appointment">
							<Row>
								<div className="footerTextContainer">
									<p className="subtitle text-center" data-cy="subTitle">
										<FormattedMessage id="shipment.shipmentModalByTelephone" />
									</p>
								</div>
								<div className="phoneContainer">
									{isShowSupportPhone && (
										<span
											style={{ cursor: "pointer" }}
											onClick={toggleServiceCodePopup}
											className="mr-3"
										>
											<FontAwesomeIcon
												icon={faPhoneAlt}
												className="phone-icon"
												size={"2x"}
												color={"#2c9dd3"}
											/>
										</span>
									)}
									<div>
										{isShowSupportPhone && (
											<span
												onClick={toggleServiceCodePopup}
												className="phoneNumber cy-phoneNumber"
											>
												{`${supportPhone.substr(0, 3)}
                       ${supportPhone.substr(3, 4)}
                       ${supportPhone.substr(7, 3)}`}
											</span>
										)}
										<p className="timeText mb-0">
											<FormattedMessage
												id="header.time"
												defaultMessage="Mon-Fri from 9 am to 6 pm"
											/>
										</p>
									</div>
									<ServiceCodePopup
										onClick={toggleServiceCodePopup}
										isOpenServiceCodePopUp={isServiceCodePopupOpen}
										department={currentDepartment?.slug || ""}
										phoneNumber={
											(supportPhone &&
												`${supportPhone.substr(0, 3)}
              ${supportPhone.substr(3, 4)}
              ${supportPhone.substr(7, 3)}`) ||
											""
										}
									/>
								</div>
							</Row>
						</div>
					</div>
				</Col>
			</Row>
		</div>
	);
};

export default LocationInput;

const isObject = (val: any) => {
	return typeof val === "object" && val !== null;
};

const classnames = (...args: any[]) => {
	const classes: any = [];
	args.forEach((arg: any) => {
		if (typeof arg === "string") {
			classes.push(arg);
		} else if (isObject(arg)) {
			Object.keys(arg).forEach(key => {
				if (arg[key]) {
					classes.push(key);
				}
			});
		} else {
			throw new Error(
				"`classnames` only accepts string or object as arguments"
			);
		}
	});

	return classes.join(" ");
};
