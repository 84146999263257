/**
 * Add all order related action types here
 */

export const SYNCHRONIZE_ORDER = "SYNCHRONIZE_ORDER";
export const GET_SYNCHRONIZED_DATA = "GET_SYNCHRONIZED_DATA";
export const SUBMIT_ORDER = "SUBMIT_ORDER";
export const SUBMIT_ORDER_FAIL = "SUBMIT_ORDER_FAIL";
export const SUBMIT_ORDER_RESPONSE_DETAILS = "SUBMIT_ORDER_RESPONSE_DETAILS";
export const UPDATE_SELECTED_SERVICES = "UPDATE_SELECTED_SERVICES";
export const UPDATE_SELECTED_SERVICES_WITH_SYNCED_DATA =
	"UPDATE_SELECTED_SERVICES_WITH_SYNCED_DATA";
export const SAVE_ORDER = "SAVE_ORDER";
export const SAVE_SYNCHRONIZED_DATA = "SAVE_SYNCHRONIZED_DATA";
export const UPDATE_SELECTED_SERVICE_PRICES = "UPDATE_SELECTED_SERVICE_PRICES";
export const RESET_ERROR = "RESET_ERROR";
export const BEEN_ON_SECOND_STEP = "BEEN_ON_SECOND_STEP";
export const GET_ORDER_ERROR = "GET_ORDER_ERROR";
