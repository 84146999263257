import React, { FC, useEffect, useState } from "react";
import { Button, Toast, ToastBody, ToastHeader } from "reactstrap";
import { useSelector } from "react-redux";

import "./styles/Notification.scss";
import { INotificationProps } from "./Interface/props/Notification";
import { IRootReducerState } from "../../common/interface/store/reducer/Reducer";

/**
 * Sample component for greeting
 * @param props
 * @constructor
 */
const Notification: FC<INotificationProps> = props => {
	// destructured all props
	const { show, title, description, toggle, time, image, type } = props;
	const [showNotification, setShowNotification] = useState<boolean>(false);

	/**
	 * Get current couriers
	 */
	const currentDepartment = useSelector(
		(state: IRootReducerState) => state.departmentReducer.currentDepartment
	);

	useEffect(() => {
		setShowNotification(show);
		const timer = setTimeout(() => {
			toggle();
		}, 5000);
		return () => clearTimeout(timer);
	}, [show, toggle]);

	return (
		<div className="displayPosition">
			{showNotification && (
				<Toast className="fadeInLeftBig">
					<ToastHeader>
						<Button close className="buttonClose" onClick={toggle} />
					</ToastHeader>
					<ToastBody>
						<div className="noti-block">
							{type === "ws-notification" ? (
								<img
									height="100px"
									width="100px"
									src={image ? image : currentDepartment?.image.small}
									alt=""
								/>
							) : (
								""
							)}
							<div className="noti-content">
								<h3 className={title == "Error" ? "text-danger" : ""}>
									{title}
								</h3>
								<p className="noti-description">{description}</p>
								<p className="timeText">{time}</p>
							</div>
						</div>
					</ToastBody>
				</Toast>
			)}
		</div>
	);
};

export default Notification;
