/**
 * Register all action related couriers here
 */
import {
	synchronizeOrder,
	getOrder,
	submitOrder,
	getOrderConfirm,
	getOrderDetails,
	extendQuote
} from "../../apiService/order/orderServices";
import { getCredentials } from "common/utilities/department/department";
import {
	GET_SYNCHRONIZED_DATA,
	SUBMIT_ORDER_FAIL,
	SUBMIT_ORDER,
	SAVE_ORDER,
	GET_ORDER_ERROR,
	SAVE_SYNCHRONIZED_DATA,
	RESET_ERROR
} from "store/actionTypes/order/orderActionTypes";
import {
	ISubmitVehicleOrder,
	ISubmitCouriersOrder,
	ISubmitDefaultOrder,
	IOrderErrorState
} from "store/interface/order/Order";

/**
 * Handle synchronize order action
 * @param {object} payload
 */
export const synchronizeOrderAction = (
	payload: {
		from?: string;
		to?: string;
		vehicle_id?: number;
	},
	department: string
) => {
	return (dispatch: any) => {
		const data = getCredentials(department);
		synchronizeOrder({ ...data, data: payload }).then((res: any) => {
			dispatch({
				type: SAVE_SYNCHRONIZED_DATA,
				payload: res.data
			});
		});
	};
};

/**
 * Handle get synchronized order action
 * @param {string} department
 */
export const getOrderAction = (department: string) => {
	return (dispatch: any) => {
		const data = getCredentials(department);
		getOrder({ ...data })
			.then((res: any) => {
				dispatch({
					type: GET_SYNCHRONIZED_DATA,
					payload: res
				});
			})
			.catch(err => {
				dispatch({
					type: GET_SYNCHRONIZED_DATA,
					payload: err.response
						? err.response.data.message
						: "Known error occured"
				});
			});
	};
};

/**
 * Handle submit order action
 * @param {ISubmitOrder} data
 */
export const submitOrderAction = (
	data: ISubmitVehicleOrder | ISubmitCouriersOrder | ISubmitDefaultOrder,
	department: string
) => {
	return (dispatch: any) => {
		// We dispatch action here to show loading and on fail we set loading to false
		dispatch({ type: SUBMIT_ORDER });
		submitOrder(data, department)
			.then(async (res: any) => {
				await localStorage.clear();
				window.location.href = res.redirect_location;
			})
			.catch((err: any) => {
				let errorData: IOrderErrorState = {
					errorData: err.response && err.response.data,
					status: err.response.status
				};
				dispatch({
					type: SUBMIT_ORDER_FAIL,
					payload: errorData
				});
			});
	};
};

/**
 *Order Accept
 * @param {number} id
 */
export const getOrderConfirmAction = (
	department: string,
	orderId: string,
	orderHash: string,
	updatedData: Object
) => {
	return (dispatch: any) => {
		getOrderConfirm(department, orderId, orderHash, updatedData)
			.then(async (res: any) => {
				await localStorage.clear();
				window.location.href = res.redirect_location;
			})
			.catch((err: any) => {
				let errorData: IOrderErrorState = {
					errorData: err.response && err.response.data,
					status: err.response.status
				};
				dispatch({
					type: SUBMIT_ORDER_FAIL,
					payload: errorData
				});
			});
	};
};

/**
 *Get Order Details
 * @param {number} id
 */
export const getOrderDetailsAction = (orderId: string, OrderHash: string) => {
	return (dispatch: any) => {
		getOrderDetails(orderId, OrderHash)
			.then((res: any) => {
				dispatch({
					type: SAVE_ORDER,
					payload: res
				});
			})
			.catch((error: any) => {
				dispatch({
					type: GET_ORDER_ERROR,
					payload: error.response
						? error.response.data.message
						: "Something went wrong."
				});
			});
	};
};

/**
 * Extend order quote
 * @param {number} id
 */
export const extendOrderQuote = (
	orderId: number,
	hash: string,
	callBack: any
) => {
	return (dispatch: any) => {
		extendQuote(orderId, hash)
			.then((res: any) => {
				callBack(true, res);
			})
			.catch((error: any) => {
				callBack(
					false,
					error.response ? error.response.data.message : "Something went wrong."
				);
			});
	};
};

/**
 * Handle reset order error action
 */
export const resetOrderError = () => {
	return (dispatch: any) => {
		return dispatch({
			type: RESET_ERROR
		});
	};
};
