/**
 * Check auth user
 */
export const isLogin = () => {
	return true;
};

/**
 * Check development mode
 */
export const idDevEnv = () => {
	return process.env.NODE_ENV === "development";
};
