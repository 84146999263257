/**
 * It is shipment reducer where shipment related all action are handle and manage state for shipment
 */
import { initialShipmentState } from "./InitialShipmentState";
import { IAction } from "common/interface/store/action/Action";
import { ICourierState } from "../../interface/shipment/Shipment";
import {
	SET_COURIERS,
	SET_FROM_DATE,
	SET_FROM_TIME,
	SET_TO_DATE,
	SET_TO_TIME,
	SET_SELECTED_COURIER_VEHICLE,
	SET_COURIER_VEHICLE_LIST,
	SET_COURIER_VEHICLE_LIST_FAIL,
	RESET_COURIER_VEHICLE_LIST
} from "../../actionTypes/shipment/shipmentActionTypes";
import { GET_SYNCHRONIZED_DATA } from "store/actionTypes/order/orderActionTypes";

/**
 *
 * Shipment reducer which handle all action related shipment
 *
 * @param state
 * @param action
 */
export default (
	state: ICourierState = initialShipmentState,
	action: IAction
): ICourierState => {
	switch (action.type) {
		case SET_COURIERS:
			return {
				...state,
				courierList: action.payload,
				courierVehicleList: [],
				transportation_time: 0,
				error: false
			};
		case SET_FROM_DATE:
			return {
				...state,
				fromDate: action.payload
			};
		case SET_FROM_TIME:
			return {
				...state,
				fromTime: action.payload
			};
		case SET_TO_DATE:
			return {
				...state,
				toDate: action.payload
			};
		case SET_TO_TIME:
			return {
				...state,
				toTime: action.payload
			};
		case SET_COURIER_VEHICLE_LIST:
			return {
				...state,
				error: false,
				transportation_time: action.payload.transportation_time,
				courierVehicleList: action.payload.vehicleList.map((vehicle: any) => {
					if (!vehicle.image) {
						vehicle.image = "no_image.jpg";
					}
					vehicle.checked = false;
					return vehicle;
				})
			};
		case SET_COURIER_VEHICLE_LIST_FAIL:
			return {
				...state,
				courierVehicleList: [],
				error: action.payload
			};
		case RESET_COURIER_VEHICLE_LIST:
			return {
				...state,
				courierVehicleList: []
			};
		case GET_SYNCHRONIZED_DATA:
			const syncedData = action.payload;
			const data: any = {};
			const currentDate = new Date();

			if (syncedData.preferred_pickup_moments) {
				if (
					syncedData.preferred_pickup_moments[0].datetime &&
					new Date(syncedData.preferred_pickup_moments[0].datetime) <
						currentDate
				) {
					data.toDate = new Date();
				} else {
					data.toDate = new Date(
						syncedData.preferred_pickup_moments[0].datetime
					);
				}
				if (
					syncedData.preferred_pickup_moments[0].time &&
					new Date(syncedData.preferred_pickup_moments[0].datetime) <
						currentDate
				)
					data.toTime = syncedData.preferred_pickup_moments[0].time;
			}
			if (syncedData.preferred_delivery_moments) {
				if (
					syncedData.preferred_delivery_moments[0].datetime &&
					new Date(syncedData.preferred_pickup_moments[0].datetime) <
						currentDate
				) {
					data.fromDate = new Date();
				} else {
					data.fromDate = new Date(
						syncedData.preferred_delivery_moments[0].datetime
					);
				}
				if (syncedData.preferred_delivery_moments[0].time)
					data.fromTime = syncedData.preferred_delivery_moments[0].time;
			}
			if (syncedData.transportation_vehicle_id) {
				const vehicleList = state.courierVehicleList.map(vehicle => {
					vehicle.checked = vehicle.id === syncedData.transportation_vehicle_id;
					return vehicle;
				});
				data.courierVehicleList = vehicleList;
			}
			return {
				...state,
				...data
			};
		case SET_SELECTED_COURIER_VEHICLE:
			const vehicleList = state.courierVehicleList.map(vehicle => {
				vehicle.checked = vehicle.id === action.payload;
				return vehicle;
			});
			return {
				...state,
				courierVehicleList: vehicleList
			};
		default:
			return state;
	}
};
