/**
 * It is shipment reducer where shipment related all action are handle and manage state for shipment
 */
import { initialOrderState } from "./InitialOrderState";
import { IAction } from "common/interface/store/action/Action";
import {
	SUBMIT_ORDER_FAIL,
	SUBMIT_ORDER_RESPONSE_DETAILS,
	SUBMIT_ORDER,
	SAVE_ORDER,
	GET_ORDER_ERROR,
	GET_SYNCHRONIZED_DATA,
	UPDATE_SELECTED_SERVICES,
	UPDATE_SELECTED_SERVICES_WITH_SYNCED_DATA,
	UPDATE_SELECTED_SERVICE_PRICES,
	SAVE_SYNCHRONIZED_DATA,
	RESET_ERROR,
	BEEN_ON_SECOND_STEP
} from "../../actionTypes/order/orderActionTypes";
import { IOrderState } from "store/interface/order/Order";
import { IServiceType } from "store/interface/vehicles/Vehicles";
import {
	SHOW_PRICE_LOADER,
	SET_VEHICLE_SERVICES
} from "store/actionTypes/vehicles/vehiclesActionTypes";

/**
 *
 * Shipment reducer which handle all action related shipment
 *
 * @param state
 * @param action
 */
export default (
	state: IOrderState = initialOrderState,
	action: IAction
): IOrderState => {
	switch (action.type) {
		case SUBMIT_ORDER_FAIL:
			return {
				...state,
				errors: action.payload,
				loading: false
			};
		case SUBMIT_ORDER_RESPONSE_DETAILS:
			return {
				...state,
				response: action.payload,
				loading: false
			};
		case SUBMIT_ORDER:
			return {
				...state,
				loading: true
			};
		case SAVE_SYNCHRONIZED_DATA:
			return {
				...state,
				lastSyncedData: action.payload
			};
		case GET_SYNCHRONIZED_DATA:
			return {
				...state,
				syncedData: action.payload
			};
		case UPDATE_SELECTED_SERVICES_WITH_SYNCED_DATA:
			let filteredSelectedServices = [];
			if (state.syncedData && state.syncedData.selected_services) {
				filteredSelectedServices = action.payload.filter(
					(service: IServiceType) => {
						return (
							state.syncedData.selected_services.findIndex(
								(x: { id: number }) => x.id === service.id
							) !== -1
						);
					}
				);
			}
			const finalFilteredSelectedServices = filteredSelectedServices.map(
				(service: IServiceType) => {
					let data = state.syncedData.selected_services.find(
						(x: any) => x.id == service.id
					);
					if (data.amount) {
						return { ...service, numberOfTimes: data.amount };
					} else {
						return service;
					}
				}
			);
			return {
				...state,
				selectedServices: finalFilteredSelectedServices
			};
		case UPDATE_SELECTED_SERVICE_PRICES:
			let servicesWithupdatedPrice = [];

			if (state.selectedServices.length) {
				servicesWithupdatedPrice = action.payload.filter(
					(service: IServiceType) => {
						return (
							state.selectedServices.findIndex(x => x.id === service.id) !== -1
						);
					}
				);
			}

			let priceFilteredServices = servicesWithupdatedPrice.filter(
				(service: IServiceType) =>
					service.service_price && service.service_price.price !== null
			);

			if (state.selectedServices.length) {
				priceFilteredServices = priceFilteredServices.map(
					(service: IServiceType) => {
						const selectedService = state.selectedServices.find(
							(s: IServiceType) => s.id == service.id
						);
						if (selectedService && selectedService.numberOfTimes) {
							return {
								...service,
								numberOfTimes: selectedService.numberOfTimes
							};
						}
						return service;
					}
				);
			}

			return {
				...state,
				selectedServices: priceFilteredServices
			};
		case SET_VEHICLE_SERVICES:
			return {
				...state,
				priceLoader: false
			};
		case SHOW_PRICE_LOADER:
			return {
				...state,
				priceLoader: true
			};
		case SAVE_ORDER:
			return {
				...state,
				order: action.payload.data,
				required_fields: action.payload.required_fields
			};
		case GET_ORDER_ERROR:
			return {
				...state,
				getOrderError: action.payload
			};
		case BEEN_ON_SECOND_STEP:
			return {
				...state,
				hasBeenOnSecondStep: true
			};
		case UPDATE_SELECTED_SERVICES:
			let services = [];
			if (action.payload.checked) {
				const isAlreadySelected: any = state.selectedServices.find(
					x => x.id === action.payload.service.id
				);
				if (isAlreadySelected && !isAlreadySelected.numberOfTimes) return state;
				if (isAlreadySelected && isAlreadySelected.numberOfTimes) {
					services = [
						...state.selectedServices.filter(
							service => service.id !== isAlreadySelected.id
						),
						action.payload.service
					];
				} else {
					services = [...state.selectedServices, action.payload.service];
				}
			} else if (action.payload.refresh) {
				services = action.payload.service;
			} else {
				services = state.selectedServices.filter(
					service =>
						service &&
						action.payload.service &&
						service.id !== action.payload.service.id
				);
			}
			return {
				...state,
				selectedServices: services
			};
		case RESET_ERROR:
			return {
				...state,
				errors: null
			};
		default:
			return state;
	}
};
