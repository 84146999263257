import React, { FC } from "react";
import MainApp from "./containers/App";
import { Provider } from "react-redux";
import store from "./store";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
	faPhoneAlt,
	faInfoCircle,
	faCaretRight,
	faTrash,
	faCheckSquare,
	faCheck,
	faSpinner,
	faClock,
	faMapMarkerAlt,
	faPencilAlt,
	faTextHeight
} from "@fortawesome/free-solid-svg-icons";
import Pusher from "pusher-js";

library.add(
	faPhoneAlt,
	faCaretRight,
	faInfoCircle,
	faTrash,
	faCheckSquare,
	faCheck,
	faSpinner,
	faClock,
	faMapMarkerAlt,
	faPencilAlt,
	faTextHeight
);

(window as any).Pusher = Pusher;

const App: FC = () => {
	return (
		<Provider store={store}>
			<MainApp />
		</Provider>
	);
};

export default App;
