import * as apiService from "../index";
import { IGetOrder, ISubmitOrder, IVerifyEmail } from "store/interface/order/Order";
import { getCredentials } from "common/utilities/department/department";

/**
 * synchronize order data
 */
export const synchronizeOrder = (data: any) => {
	if (!data.data) {
		data = { ...data, data: [] };
	}
	return apiService.post("orders/order-synchronization", data);
};

/**
 * synchronize order data
 */
export const getOrderConfirm = (
	department: string,
	id: string,
	hash: string,
	updatedData: Object
) => {
	const credentials = getCredentials(department);
	return apiService.post(
		`booking-service/orders/${id}/accept?hash=${hash}`,
		updatedData,
		{},
		{ "X-Idempotency": credentials.uuid }
	);
};

/**
 * synchronize order data
 */
export const getOrderDetails = (id: string, hash: string) => {
	return apiService.get(`booking-service/orders/${id}?hash=${hash}`);
};

/**
 * Get service code
 */
export const getServiceCode = (data: IGetOrder) => {
	return apiService.put(
		`orders/order-synchronization?uuid=${data.uuid}&secret=${data.secret}`
	);
};

/**
 * Extend order quote
 */
export const extendQuote = (orderId: number, hash: string) => {
	return apiService.put(`orders/${orderId}/extend?hash=${hash}`);
};

/**
 * Get order data
 */
export const getOrder = (data: IGetOrder) => {
	return apiService.get(
		`orders/order-synchronization?uuid=${data.uuid}&secret=${data.secret}`
	);
};

/**
 * Get delivery options
 */
export const getDeliveryOptions = (base64: string) => {
	return apiService.get(`booking-service/delivery/options?data=${base64}`);
};

/**
 * Get payment options
 */
export const getPaymentOptions = (base64: string) => {
	return apiService.get(`orders/payment-options?data=${base64}`);
};

/**
 * Get quote page payment options
 */
export const getQuotePaymentOptions = (orderId: string) => {
	return apiService.get(`orders/${orderId}/payment-options`);
};

/**
 * Submit order
 */
export const submitOrder = (data: ISubmitOrder, department: string) => {
	const credentials = getCredentials(department);
	return apiService.post(
		"orders",
		data,
		{},
		{ "X-Idempotency": credentials.uuid }
	);
};

/**
 * Submit order
 */
// export const validateForm = (data: ISubmitOrder, department: string) => {
// 	const credentials = getCredentials(department);
// 	return apiService.post(
// 		"orders",
// 		data,
// 		{},
// 		{ "X-Idempotency": credentials.uuid, precognition: true }
// 	);
// };

export const confirmEmail = (data: IVerifyEmail) => {
	return apiService.put(`orders/${data.id}/verify-email`, {}, { hash: data.hash });
}
