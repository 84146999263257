/**
 * Add all vehicle related action types here
 */

export const SET_VEHICLE_DATA = "SET_VEHICLE_DATA";
export const REMOVE_VEHICLE_DATA = "REMOVE_VEHICLE_DATA";
export const SET_VEHICLE_ERROR = "SET_VEHICLE_ERROR";
export const SET_VEHICLE_SERVICES = "SET_VEHICLE_SERVICES";
export const SET_VEHICLE_BRANDS = "SET_VEHICLE_BRANDS";
export const SET_VEHICLE_MODELS = "SET_VEHICLE_MODELS";
export const SET_VEHICLE_YEARS = "SET_VEHICLE_YEARS";
export const SET_VEHICLE_FUELS = "SET_VEHICLE_FUELS";
export const SET_TOKEN = "SET_TOKEN";
export const ERROR = "ERROR";
export const RESET_ERROR = "RESET_ERROR";
export const GET_AUTOCOMPLETE_DATA = "GET_AUTOCOMPLETE_DATA";
export const SET_AUTOCOMPLETE_DATA = "SET_AUTOCOMPLETE_DATA";
export const SET_AUTOCOMPLETE_DATA_LOADING = "SET_AUTOCOMPLETE_DATA_LOADING";
export const SHOW_PRICE_LOADER = "SHOW_PRICE_LOADER";
export const ADD_DAMAGE_ROW = "ADD_DAMAGE_ROW";
export const REMOVE_DAMAGE_ROW = "REMOVE_DAMAGE_ROW";
export const UPDATE_DAMAGE_ROW = "UPDATE_DAMAGE_ROW";
export const RESET_DAMAGE_ROW = "RESET_DAMAGE_ROW";
export const SHOW_VEHICLE_LOADER = "SHOW_VEHICLE_LOADER";
export const GET_VEHICLE_DATE = "GET_VEHICLE_DATE";
export const SET_VEHICLE_DATE = "SET_VEHICLE_DATE";
