import { IOrderState } from "../../interface/order/Order";

export const initialOrderState: IOrderState = {
	errors: null,
	response: null,
	loading: false,
	order: null,
	getOrderError: "",
	lastSyncedData: null,
	hasBeenOnSecondStep: false,
	priceLoader: false,
	syncedData: null,
	selectedServices: [],
	required_fields: null
};
