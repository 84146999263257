import {IServiceType} from "../store/interface/vehicles/Vehicles";

export const BOOKING_FEE_SERVICE: IServiceType = {
	actions: [{
		description: "Boekingsfee"
	}],
	alias: "",
	category: {
		id: 99999,
		name: "Fees",
		order: 99999,
		department: "",
		image: ""
	},
	department: "",
	isChecked: false,
	key: "",
	on_sale: false,
	options: [],
	order: 0,
	sale: [{}],
	id: -5, // Use a unique ID that won't conflict with existing services
	name: "Booking Fee",
	price: 5.00, // Set the appropriate price
	description: "Mandatory booking fee for all orders",
	service_type_id: 9999,
	service_vehicle_id: 9999,
	service_price: {
		price: 1900,
		price_before: 0,
		probability: 100,
		required: true
	},
	transportation_vehicle: {
		price_per_km: 0
	}
};
