import { tawkTos } from "local-packages";
import { v4 as uuidv4 } from "uuid";

/**
 * Write common utilities for couriers
 */
export const getCurrentDepartment = () => {};

export const injectTawkTo = (tawkToId: number) => {
	tawkTos(tawkToId);
};

/**
 * Get credentials from localStorage
 * @param {string} department
 */
export const getCredentials = (department: string) => {
	let data;
	if (localStorage.getItem(`${department}-credentials`)) {
		data = JSON.parse(localStorage.getItem(`${department}-credentials`)!);
	} else {
		data = { uuid: uuidv4(), secret: makeSecret() };
		localStorage.setItem(`${department}-credentials`, JSON.stringify(data));
	}
	return data;
};

const makeSecret = () => {
	var result = "";
	var characters =
		"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
	var charactersLength = characters.length;
	for (var i = 0; i < 30; i++) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
	}
	return result;
};
