/**
 * It is vehicles reducer where vehicles related all action are handle and manage state for vehicles
 */
import { IAction } from "common/interface/store/action/Action";
import { initialVehiclesState } from "./InitialVehiclesState";
import {
	IVehiclesState,
	IDamageRowType
} from "../../interface/vehicles/Vehicles";
import {
	SET_VEHICLE_DATA,
	SET_VEHICLE_ERROR,
	SET_VEHICLE_SERVICES,
	SET_VEHICLE_BRANDS,
	SET_VEHICLE_MODELS,
	SET_VEHICLE_FUELS,
	ERROR,
	RESET_ERROR,
	REMOVE_VEHICLE_DATA,
	SET_AUTOCOMPLETE_DATA,
	SET_AUTOCOMPLETE_DATA_LOADING,
	GET_AUTOCOMPLETE_DATA,
	SET_VEHICLE_YEARS,
	SET_TOKEN,
	ADD_DAMAGE_ROW,
	REMOVE_DAMAGE_ROW,
	UPDATE_DAMAGE_ROW,
	RESET_DAMAGE_ROW,
	SHOW_VEHICLE_LOADER,
	SET_VEHICLE_DATE
} from "../../actionTypes/vehicles/vehiclesActionTypes";
import { GET_SYNCHRONIZED_DATA } from "store/actionTypes/order/orderActionTypes";

/**
 *
 * Vehicles reducer which handle all action related vehicles
 *
 * @param state
 * @param action
 */
export default (
	state: IVehiclesState = initialVehiclesState,
	action: IAction
): IVehiclesState => {
	switch (action.type) {
		case SET_VEHICLE_DATA:
			return {
				...state,
				error: null,
				vehicle: action.payload,
				loading: false
			};
		case SET_VEHICLE_DATE:
			return {
				...state,
				error: null,
				timeOptions: action.payload
			};
		case REMOVE_VEHICLE_DATA:
			return {
				...state,
				error: null,
				vehicle: null,
				selection: state.selection
					? {
							...state.selection,
							vehiclesModelList: [],
							vehiclesFuelList: [],
							vehiclesModelYears: []
					  }
					: null
			};
		case SET_VEHICLE_ERROR:
			return {
				...state,
				vehicle: null,
				error: action.payload,
				loading: false
			};
		case SET_VEHICLE_SERVICES:
			return {
				...state,
				services: action.payload
			};
		case SET_VEHICLE_BRANDS:
			return {
				...state,
				selection: {
					vehiclesBrandList: action.payload,
					vehiclesModelYears: [],
					vehiclesModelList: null,
					vehiclesFuelList: null
				}
			};
		case SET_VEHICLE_MODELS:
			return {
				...state,
				selection: state.selection
					? {
							...state.selection,
							vehiclesModelList: action.payload
					  }
					: null
			};
		case SET_VEHICLE_YEARS:
			return {
				...state,
				selection: state.selection
					? {
							...state.selection,
							vehiclesModelYears: action.payload
					  }
					: null
			};
		case SET_VEHICLE_FUELS:
			return {
				...state,
				selection: state.selection
					? {
							...state.selection,
							vehiclesFuelList: action.payload
					  }
					: null
			};

		case ERROR:
			return {
				...state,
				error:
					action.payload === "Request failed with status code 404"
						? "Vehicle Not Found"
						: action.payload,
				vehicle: null
			};

		case RESET_ERROR:
			return {
				...state,
				error: null
			};
		case SHOW_VEHICLE_LOADER:
			return {
				...state,
				loading: true
			};

		case GET_AUTOCOMPLETE_DATA:
			return {
				...state,
				loadingAutoCompleteData: true
			};
		case SET_AUTOCOMPLETE_DATA_LOADING:
			return {
				...state,
				loadingAutoCompleteData: false
			};
		case SET_TOKEN:
			return {
				...state,
				token: action.payload
			};

		case SET_AUTOCOMPLETE_DATA:
			return {
				...state,
				error: null,
				loadingAutoCompleteData: false,
				autoCompleteData: action.payload
			};
		case RESET_DAMAGE_ROW:
			return {
				...state,
				damageRows: []
			};
		case ADD_DAMAGE_ROW:
			return {
				...state,
				damageRows: [...state.damageRows, action.payload]
			};
		case REMOVE_DAMAGE_ROW:
			return {
				...state,
				damageRows: state.damageRows.filter(
					(rowData: IDamageRowType) => rowData.id !== action.id
				)
			};
		case GET_SYNCHRONIZED_DATA:
			let getUpdatedDamgeRows: IDamageRowType[] = [];
			if (action.payload.selected_services) {
				action.payload.selected_services.forEach((selectedService: any) => {
					if (selectedService.meta && selectedService.meta.length) {
						getUpdatedDamgeRows = selectedService.meta.map(
							(damageRowData: any) => ({
								id: damageRowData.rowId,
								selectedPart: damageRowData.location,
								upload_id: damageRowData.upload_id,
								secret_id: damageRowData.secret_id,
								name: damageRowData.name
							})
						);
					}
				});
			}
			return {
				...state,
				damageRows: getUpdatedDamgeRows
			};
		case UPDATE_DAMAGE_ROW:
			const updatedDamgeRows = state.damageRows.map(
				(rowData: IDamageRowType) => {
					if (rowData.id === action.payload.id) {
						if (action.payload.partName) {
							return {
								...rowData,
								selectedPart: action.payload.partName
							};
						} else if (action.payload.upload_id) {
							return {
								...rowData,
								upload_id: action.payload.upload_id,
								name: action.payload.name,
								secret_id: action.payload.secret_id
								// fileUrl: action.payload.fileUrl
							};
						} else {
							return rowData;
						}
					} else {
						return rowData;
					}
				}
			);

			return {
				...state,
				damageRows: updatedDamgeRows
			};
		default:
			return state;
	}
};
