import React, { useState } from "react";
import { Popover, PopoverBody } from "reactstrap";

import "./styles/ShipmentDetailsInput.scss";
import { IShipmentDetailsInputProps } from "./Interface/props/ShipmentDetailsInputProps";

const ShipmentDetailsInput = ({
	title,
	initialValue,
	description,
	id,
	descriptionTitle,
	max,
	formVerified,
	className = "",
	icon,
	onChangeVal
}: IShipmentDetailsInputProps) => {
	const [inputPopoverOpen, setPopoverOpen] = useState(false);
	const [isFocused, setIsFocused] = useState(false);
	const [mouseOnPopup, setMouseOnPopup] = useState(false);

	return (
		<div className={`input-wrap ${className}`}>
			<span className="input-title">{title}</span>
			<div style={{ display: "flex", alignItems: "center" }}>
				<span className="dimention-icon">
					<i className={icon} aria-hidden="true"></i>
				</span>
				<input
					id={id}
					onInput={(e: any) => {
						e.target.value = Math.max(0, parseInt(e.target.value))
							.toString()
							.slice(0, title === "Gewicht" ? 4 : 3);
					}}
					onChange={e =>
						onChangeVal((+e.currentTarget.value as unknown) as number)
					}
					value={initialValue && initialValue.toString()}
					className={
						!formVerified ? `${title === "Gewicht" && "km-bg"}` : "disableInput"
					}
					type="number"
					onMouseEnter={() => setPopoverOpen(true)}
					onFocus={() => {
						setIsFocused(true);
						setPopoverOpen(true);
					}}
					onMouseLeave={() => {
						if (!isFocused) setPopoverOpen(false);
					}}
					onBlur={e => {
						if (!mouseOnPopup) {
							setIsFocused(false);
							setPopoverOpen(false);
						} else {
							setTimeout(() => {
								setIsFocused(false);
								setPopoverOpen(false);
							}, 2000);
						}
					}}
					max={max}
					min={1}
					disabled={formVerified}
				/>
				<span className="dimention-symbol">
					{title === "Gewicht" ? "kg" : "cm"}
				</span>
			</div>

			<Popover placement="top" isOpen={inputPopoverOpen} target={id}>
				<PopoverBody
					onMouseOver={() => setMouseOnPopup(true)}
					onMouseOut={() => setMouseOnPopup(false)}
					onBlur={() => setMouseOnPopup(false)}
				>
					<b>{descriptionTitle}</b> {description}
				</PopoverBody>
			</Popover>
		</div>
	);
};

export default ShipmentDetailsInput;
