import React, { FC, useEffect, useState } from "react";
import { Alert, Spinner } from "reactstrap";
import { useIntl } from "react-intl";

import "./styles/Unsubscribe.scss";
import { useLocation } from "react-router-dom";
import { unsubApk } from "store/apiService/department/departmentServices";

const UnsubscribeApk: FC = () => {
	const useQuery = () => new URLSearchParams(useLocation().search);
	const query = useQuery();
	const intl = useIntl();
	const [loading, setLoading] = useState(true);
	const [msg, setMsg] = useState("");

	const SuccessMsg = intl.formatMessage({
		id: "app.successUnsubscribed",
		defaultMessage: "You have successfully unsubscribed"
	});

	const errorUnsubscribed = intl.formatMessage({
		id: "app.errorUnsubscribed",
		defaultMessage: "You have successfully unsubscribed"
	});

	useEffect(() => {
		const hash = query.get("hash");
		const id = query.get("id");
		if (hash && id) {
			unsubApk(id, hash)
				.then(res => {
					setLoading(false);
					setMsg("success");
				})
				.catch(err => {
					setLoading(false);
					setMsg(
						err.response ? err.response.data.message : "Please try again later"
					);
				});
		} else {
			setLoading(false);
			setMsg("invalid");
		}
	}, []);

	return (
		<div className="main-wrapper">
			{loading ? (
				<Spinner style={{ width: "3rem", height: "3rem" }} color="secondary" />
			) : msg === "success" && !loading ? (
				<Alert className="text-left w-100" color="success">
					{SuccessMsg}
				</Alert>
			) : (
				<Alert className="text-left w-100" color="danger">
					{msg === "invalid" ? errorUnsubscribed : msg}
				</Alert>
			)}
		</div>
	);
};

export default UnsubscribeApk;
