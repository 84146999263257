/**
 * Register all action related shipment here
 */
import {
	SET_COURIER_VEHICLE_LIST,
	SET_COURIERS,
	SET_SELECTED_COURIER_VEHICLE,
	SET_COURIER_VEHICLE_LIST_FAIL,
	RESET_COURIER_VEHICLE_LIST
} from "../../actionTypes/shipment/shipmentActionTypes";
import {
	getCargoTypes,
	getCourierVehicleType
} from "store/apiService/shipment/shipmentServices";
import { ICourier } from "store/interface/shipment/Shipment";

/**
 * Handle get couriers action
 */
export const getCouriersAction = () => {
	return (dispatch: any) => {
		getCargoTypes()
			.then((res: { data: ICourier[] }) => {
				dispatch({
					type: SET_COURIERS,
					payload: res.data
				});
			})
			.catch(err => {
				console.log(err);
			});
	};
};

export const setSelectedCourierVehicle = (id: number) => {
	return (dispatch: any) => {
		dispatch({
			type: SET_SELECTED_COURIER_VEHICLE,
			payload: id
		});
	};
};

/**
 * Handle get couriers action
 */
export const getCourierVehicleAction = (data: any) => {
	return (dispatch: any) => {
		getCourierVehicleType(data)
			.then(res => {
				const data = {
					vehicleList: res.data,
					transportation_time: res.meta.transportation_time
				};
				dispatch({
					type: SET_COURIER_VEHICLE_LIST,
					payload: data
				});
			})
			.catch(error => {
				dispatch({
					type: SET_COURIER_VEHICLE_LIST_FAIL,
					payload: true
				});
				console.log(error);
			});
	};
};

/**
 * Handle reset courier vehicle action
 */
export const resetCourierVehicleAction = () => {
	return (dispatch: any) => {
		dispatch({
			type: RESET_COURIER_VEHICLE_LIST
		});
	};
};
