import React, { FC, useState, useEffect } from "react";
import { IHeaderProps } from "./Interface/props/HeaderProps";
import {
	Col,
	Container,
	Row,
	Dropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhoneAlt, faCommentAlt } from "@fortawesome/free-solid-svg-icons";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import "./styles/Header.scss";
import ReactStars from "component/StarRating";
import FeedbackPopup from "../../../FeedbackPopup/FeedbackPopup";
import { getFeedbackAction } from "store/actions/department/deparmentActions";
import { getCurrentDepartmentAction } from "store/actions/department/deparmentActions";
import { idDevEnv } from "../../../../common/utilities/auth/auth";
import ServiceCodePopup from "component/ServiceCodePopup/ServiceCodePopup";
import { getCurrentDepartment } from "store/apiService/department/departmentServices";
import Helmet from "react-helmet";
import { useIsShowSupportPhone } from "util/customHooks";

/**
 * Sample component for greeting
 * @param props
 * @constructor
 */

const Header: FC<IHeaderProps> = props => {
	// destructure all props here
	const { currentDepartment } = props;
	const [feedbackPopup, setFeedbackPopup] = useState(false);
	const [dropdownOpen, setOpen] = useState(false);
	const [departments, setDepartments] = useState([]);
	const [isServiceCodePopupOpen, setIsServiceCodePopupOpen] = useState(false);
	const isShowSupportPhone = useIsShowSupportPhone();
	const history = useHistory();
	const dispatch = useDispatch();
	// Count rating
	const rating =( (currentDepartment?.feedback?.average * 2) / 10);
	const toggle = () => setOpen(!dropdownOpen);
	// Count star rating
	const starRating = rating / 2;

	useEffect(() => {
		if (idDevEnv()) {
			getCurrentDepartment("?with_inactive=true").then(res => {
				setDepartments(res.data);
			});
		}
	}, []);

	const getFeedBack = () => {
		if (
			rating &&
			currentDepartment &&
			currentDepartment.feedback &&
			currentDepartment.feedback.total
		) {
			dispatch(getFeedbackAction({}, 3));
			setFeedbackPopup(true);
		}
	};

	const toggleServiceCodePopup = () =>
		setIsServiceCodePopupOpen(!isServiceCodePopupOpen);

	const changeDepartment = (department: any) => {
		if (new Date(department.active) > new Date()) {
			history.push("/coming-soon");
		} else {
			localStorage.clear();
			localStorage.setItem("selectedDepartment", department.slug);
			window.location.reload();
			dispatch(
				getCurrentDepartmentAction(idDevEnv() ? department.slug : undefined)
			);
		}
	};

	const onClickChat = () => {
		// @ts-ignore
		Freshbots.showWidget("open");
	};

	return (
		<div className="header" data-cy="header">
			<Helmet>
				<meta
					name="apple-itunes-app"
					content={`app-id=1557084014, app-argument=${window.location.origin}${window.location.pathname}${window.location.search}&department=${currentDepartment?.slug}`}
				></meta>
			</Helmet>
			<Container>
				<Row>
					<Col
						md={4}
						sm={4}
						xs={4}
						className="logo d-flex align-items-center"
						data-cy="header-logo"
					>
						<img
							className="hide-sm mt-0"
							src={currentDepartment.image.large}
							alt="logo"
						/>
						<img
							className="hide-md mt-0"
							src={currentDepartment.image.small}
							alt="logo"
						/>
					</Col>
					<Col
						data-cy="header-starRating"
						className="mid-col d-flex align-center justify-content-center"
						md={4}
						sm={4}
						xs={4}
						onClick={getFeedBack}
						style={{ cursor: "pointer" }}
					>
						<Row className="review">
							<p className="reviewstate mb-3 mb-md-0">{rating.toFixed(1)}</p>
							<Col className="review d-flex flex-column justify-content-center">
								<p className="hide-sm review-count">
									{currentDepartment?.feedback?.total}{" "}
									<FormattedMessage
										id="header.review"
										defaultMessage="Review"
									/>
								</p>
								<ReactStars
									className="startrating hide-sm"
									valueProp={starRating}
									count={5}
									edit={false}
									size={20}
									color2={"#faca30"}
								/>
								<ReactStars
									className="startrating hide-md"
									valueProp={starRating}
									count={5}
									edit={false}
									size={17}
									color2={"#faca30"}
								/>
							</Col>
						</Row>
					</Col>
					<Col
						md={4}
						sm={4}
						xs={4}
						className="d-flex align-items-center justify-content-end"
					>
						<div className="phonenumber" data-cy="header-contactInfo">
							<Row className="align-items-center">
								{window.innerWidth <= 425 && (
									<>
										<span
											style={{ cursor: "pointer", marginRight: "10px" }}
											onClick={onClickChat}
										>
											<FontAwesomeIcon
												icon={faCommentAlt}
												className="comment-alt"
											/>
										</span>
										<span
											style={{ cursor: "pointer" }}
											onClick={toggleServiceCodePopup}
										>
											<FontAwesomeIcon
												icon={faPhoneAlt}
												className="phone-icon"
											/>
										</span>
									</>
								)}
								{window.innerWidth > 425 && isShowSupportPhone && (
									<span
										style={{ cursor: "pointer" }}
										onClick={toggleServiceCodePopup}
									>
										<FontAwesomeIcon icon={faPhoneAlt} className="phone-icon" />
									</span>
								)}
								<Col className="hide-sm">
									{isShowSupportPhone && (
										<div className="phoneanchor">
											<span
												style={{ cursor: "pointer" }}
												onClick={toggleServiceCodePopup}
											>
												{`${currentDepartment.support_phone.substr(0, 3)}
                       ${currentDepartment.support_phone.substr(3, 4)}
                       ${currentDepartment.support_phone.substr(7, 3)}`}
											</span>
										</div>
									)}
									<p className="time mb-0">
										<FormattedMessage
											id="header.time"
											defaultMessage="Mon-Fri from 9 am to 6 pm"
										/>
									</p>
								</Col>
								{/*{window.innerWidth <= 768 && (*/}
								{/*	<FontAwesomeIcon icon={faBars} className="phone-icon"/>*/}
								{/*)}*/}
								{/*{window.innerWidth > 768 && (*/}
								{/*	<FontAwesomeIcon icon={faBars} className="phone-icon"/>*/}
								{/*)}*/}
							</Row>
						</div>
					</Col>
				</Row>
			</Container>
			<FeedbackPopup
				data-cy="Header-Feedbackpopup"
				show={feedbackPopup}
				toggle={() => setFeedbackPopup(false)}
				starRating={starRating}
				total={currentDepartment?.feedback?.total}
				rating={rating.toFixed(1)}
			/>
			<ServiceCodePopup
				onClick={toggleServiceCodePopup}
				isOpenServiceCodePopUp={isServiceCodePopupOpen}
				department={currentDepartment?.slug || ""}
				phoneNumber={
					(currentDepartment &&
						`${currentDepartment.support_phone.substr(0, 3)}
              ${currentDepartment.support_phone.substr(3, 4)}
              ${currentDepartment.support_phone.substr(7, 3)}`) ||
					""
				}
			/>
			{idDevEnv() && (
				<Dropdown
					isOpen={dropdownOpen}
					toggle={toggle}
					className="departmentChanger"
				>
					<DropdownToggle caret>Change Department</DropdownToggle>
					<DropdownMenu>
						{departments.map((department: any, index) => (
							<DropdownItem
								key={department + index}
								onClick={e => changeDepartment(department)}
							>
								{department.name}
							</DropdownItem>
						))}
					</DropdownMenu>
				</Dropdown>
			)}
		</div>
	);
};

export default Header;
