/**
 * It is root for store reducer
 */
import { combineReducers } from "redux";
import { IRootReducerState } from "common/interface/store/reducer/Reducer";
import departmentReducer from "./department/departmentReducer";
import shipmentReducer from "./shipment/shipmentReducer";
import vehiclesReducer from "./vehicles/vehiclesReducer";
import orderReducer from "./order/orderReducer";

/**
 *  Combine all reducer here
 */
export default combineReducers<IRootReducerState>({
	departmentReducer,
	shipmentReducer,
	vehiclesReducer,
	orderReducer
});
