import { IVehiclesState } from "../../interface/vehicles/Vehicles";

export const initialVehiclesState: IVehiclesState = {
	/**
	 * Vehicle Data
	 */
	vehicle: null,
	/**
	 * Vehicle not found error
	 */
	/*
	 * timeOptions Data
	 */
	timeOptions: null,

	error: null,
	/**
	 * Vehicle loading
	 */
	loading: false,
	/**
	 * Vehicle services data
	 */
	services: null,
	/**
	 * Vehicle selection
	 */
	selection: null,
	/**
	 * Auto completed Data
	 */
	autoCompleteData: null,
	/**
	 * Loading Auto completed Data
	 */
	loadingAutoCompleteData: false,
	/**
	 * captcha token
	 */
	token: null,
	/**
	 * Damage rows
	 */
	damageRows: []
};
