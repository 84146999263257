import React, { FC } from "react";
import { Row, Col, Input, Alert } from "reactstrap";
import { useLocation, useParams } from "react-router-dom";

import "./WinInvoice.scss";
import { useEffect } from "react";
import {
	getOrderDetails,
	submitOrderComplete
} from "store/apiService/department/departmentServices";
import { useState } from "react";
import { formatPrice } from "common/utilities";

const WinInvoice: FC = () => {
	const [orderData, setOrderData] = useState<any>(null);
	const [error, setError] = useState<any>(null);
	const [invoiceAmount, setInvoiceAmount] = useState<any>();
	const useQuery = () => new URLSearchParams(useLocation().search);
	const query = useQuery();
	let { id } = useParams<any>();

	useEffect(() => {
		if (id && query.get("hash")) {
			getOrderDetails(id, query.get("hash"))
				.then(({ data }) => {
					setOrderData(data);
				})
				.catch(err => {
					setError(
						err.response ? err.response.data.message : "Please try again later"
					);
				});
		}
	}, [id]);

	const onClickSubmit = () => {
		let price = invoiceAmount;
		if (
			invoiceAmount &&
			(invoiceAmount.includes(",") || invoiceAmount.includes("."))
		) {
			price = invoiceAmount.replace(/[,.]/g, "");
		} else {
			price = invoiceAmount * 100;
		}
		submitOrderComplete(id, query.get("hash"), {
			price: parseInt(price)
		})
			.then(res => {
				setError("success");
			})
			.catch(err => {
				setError(
					err.response ? err.response.data.message : "Please try again later"
				);
			});
	};

	return (
		<>
			{error && !orderData && (
				<Alert className="text-left mt-5 ml-auto mr-auto" color="danger">
					{error}
				</Alert>
			)}
			{orderData && (
				<div className="main-wrapper">
					<h2 className="mb-4">WIN UW FACTUURBEDRAG TERUG!</h2>
					{error && error !== "success" && (
						<Alert className="text-left" color="danger">
							{error}
						</Alert>
					)}
					{error && error === "success" && (
						<Alert className="text-left" color="success">
							Successfully submitted!
						</Alert>
					)}

					<Row>
						<Col md={5}>
							<div className="left-side">
								<b className="mb-2">Volledig factuurbedrag terugwinnen?</b>
								<p>
									Vul in wat u heeft afgerekend en maak kans om het volledige
									bedrag terug te winnen.
								</p>
								<Input
									type="text"
									name="name"
									value={orderData.name}
									disabled
								/>
								<Input
									value={invoiceAmount}
									onChange={e => setInvoiceAmount(e.target.value)}
									className=" mt-2"
									type="number"
									name="name"
									placeholder="€ Vul uw factuurbedrag in."
								/>
								<button
									type="button"
									className="btn btn-sr-yellow btn btn-secondary mt-2"
									onClick={onClickSubmit}
								>
									VERZENDEN
								</button>
							</div>
						</Col>
						<Col md={7}>
							<div className="right-side">
								<h2>VORIGE WINNAAR</h2>
								<p>
									Dhr. G. van Gorp <br /> uit Eindhoven
								</p>
								<b>Gewonnen bedrag</b>
								<br />
								<h2 className="mb-5">€ {formatPrice(43282)}</h2>
							</div>
						</Col>
					</Row>
				</div>
			)}
		</>
	);
};

export default WinInvoice;
