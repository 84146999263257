import React, { FC, Suspense } from "react";
import { useSelector } from "react-redux";

import { IRootReducerState } from "common/interface/store/reducer/Reducer";
import SrSpinner from "component/Spinner/Spinner";
import "./styles/confirm.scss";

/**
 * Calculate
 * @constructor
 */
const Order: FC = () => {
	/**
	 * get current department form redux
	 */
	let currentDepartment = useSelector(
		(state: IRootReducerState) => state.departmentReducer.currentDepartment
	);

	const getCalculateComponent = () => {
		const CalculateComponent = React.lazy(() =>
			import(`./${currentDepartment?.slug}/index`)
				.then(module => {
					return module;
				})
				.catch(error => {
					console.log("Error in loading module:", error);
				})
		);

		return <CalculateComponent />;
	};

	return (
		<Suspense fallback={<SrSpinner />}>{getCalculateComponent()}</Suspense>
	);
};

export default React.memo(Order);
