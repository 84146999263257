/**
 * Register all action related couriers here
 */
import {
	getVehicle,
	getVehicleServices,
	getVehicleBrands,
	getVehicleFuels,
	getVehicleModels,
	orderSyncService,
	setVehicleInformation,
	getAutocompleteData,
	getVehicleYears,
	dateAvailabilities
} from "../../apiService/vehicles/vehiclesServices";
import {
	SET_VEHICLE_DATA,
	SET_VEHICLE_ERROR,
	SET_VEHICLE_SERVICES,
	SET_VEHICLE_BRANDS,
	SET_VEHICLE_MODELS,
	SET_VEHICLE_FUELS,
	RESET_ERROR,
	REMOVE_VEHICLE_DATA,
	SET_AUTOCOMPLETE_DATA,
	SET_AUTOCOMPLETE_DATA_LOADING,
	SET_VEHICLE_YEARS,
	SHOW_PRICE_LOADER,
	SHOW_VEHICLE_LOADER,
	SET_VEHICLE_DATE
} from "../../actionTypes/vehicles/vehiclesActionTypes";
import {
	UPDATE_SELECTED_SERVICES_WITH_SYNCED_DATA,
	UPDATE_SELECTED_SERVICE_PRICES
} from "store/actionTypes/order/orderActionTypes";
import { SHOW_ERROR } from "store/actionTypes/department/departmentActionTypes";
import { getCredentials } from "common/utilities/department/department";

/**
 * Handle get vehicle action
 * @param {string} lisencePlate
 */
export const getVehicleAction = (lisencePlate: string) => {
	return (dispatch: any) => {
		dispatch({
			type: SHOW_VEHICLE_LOADER
		});
		getVehicle(lisencePlate)
			.then((vehicleData: any) => {
				return dispatch({
					type: SET_VEHICLE_DATA,
					payload: vehicleData
				});
			})
			.catch((err: any) => {
				return dispatch({
					type: SET_VEHICLE_ERROR,
					payload: err.response.data.message
				});
			});
	};
};
/*
 * get Vehicle Dates
 */
export const getVehicleDate = (
	payload: any,
	callback: (result: string, response: any) => void
) => {
	return (dispatch: any) => {
		dateAvailabilities(payload).then((vehicleDate: any) => {
			const vehicleDates = vehicleDate.data;
			callback("success", vehicleDates);
			return dispatch({
				type: SET_VEHICLE_DATE,
				payload: vehicleDates
			});
		});
	};
};

/**
 * Handle get vehicle services action
 */
export const getVehicleServicesAction = (
	data = null,
	captchaToken: string | null = null
) => {
	return (dispatch: any) => {
		dispatch({
			type: SHOW_PRICE_LOADER
		});
		getVehicleServices(data, captchaToken)
			.then((servicesData: any) => {
				dispatch({
					type: SET_VEHICLE_SERVICES,
					payload: servicesData.data
				});
				if (data) {
					dispatch({
						type: UPDATE_SELECTED_SERVICE_PRICES,
						payload: servicesData.data
					});
				} else {
					dispatch({
						type: UPDATE_SELECTED_SERVICES_WITH_SYNCED_DATA,
						payload: servicesData.data
					});
				}
			})
			.catch((err: any) => {
				dispatch({
					type: SHOW_ERROR,
					payload: err.response
				});
			});
	};
};

/**
 * Handle remove vehicle services data
 */
export const removeVehicleDataAction = () => {
	return (dispatch: any) => {
		return dispatch({
			type: REMOVE_VEHICLE_DATA
		});
	};
};

/**
 * Handle get vehicle brands data
 */
export const getVehicleBrandsAction = () => {
	return (dispatch: any) => {
		getVehicleBrands().then((response: any) => {
			const vehicleData = response.map((item: any) => {
				delete item.created_at;
				delete item.updated_at;
				return item;
			});
			return dispatch({
				type: SET_VEHICLE_BRANDS,
				payload: vehicleData
			});
		});
	};
};

/**
 * Handle get vehicle models action
 *@param {number} brandId
 */
export const getVehicleModelsAction = (brandId: number) => {
	return (dispatch: any) => {
		getVehicleModels(brandId).then((response: any) => {
			return dispatch({
				type: SET_VEHICLE_MODELS,
				payload: response
			});
		});
	};
};

/**
 * Handle get vehicle years action
 *@param {number} brandId
 */
export const getVehicleYearsAction = (modelId: number) => {
	return (dispatch: any) => {
		getVehicleYears(modelId).then((response: any) => {
			return dispatch({
				type: SET_VEHICLE_YEARS,
				payload: response
			});
		});
	};
};

/**
 * Handle get vehicle fuels action
 *@param {number} brandId
 */
export const getVehicleFuelsAction = (brandId: number, modelId: number) => {
	return (dispatch: any) => {
		getVehicleFuels(brandId, modelId).then((response: any) => {
			const vehicleData = response.map((item: any) => {
				delete item.created_at;
				delete item.updated_at;
				return item;
			});
			return dispatch({
				type: SET_VEHICLE_FUELS,
				payload: vehicleData
			});
		});
	};
};

/**
 * Handle reset vehicle error action
 *@param {number} brandId
 */
export const resetError = () => {
	return (dispatch: any) => {
		return dispatch({
			type: RESET_ERROR
		});
	};
};

/**
 * Handle get vehicle brands action
 *@param {number} brandId
 */
export const saveVehicleInformation = (
	brandId: number,
	modelId: number,
	fuelId: number,
	year: number,
	formatted_plate: string
) => {
	return (dispatch: any) => {
		setVehicleInformation(brandId, modelId, fuelId, year)
			.then((response: any) => {
				// if (localStorage.getItem("vehicles-credentials")) {
				const credentials = getCredentials("vehicles");
				const { uuid, secret } = credentials;
				const reqObj = {
					uuid: uuid,
					secret: secret,
					data: {
						vehicle_id: response.vehicle_id
					}
				};
				orderSyncService(reqObj).then(null);
				return dispatch({
					type: SET_VEHICLE_DATA,
					payload: response
				});
				// } else {
				// 	return dispatch({
				// 		type: "DO_NOTHING"
				// 	});
				// }
			})
			.catch(error => {
				return dispatch({
					type: "ERROR",
					payload: error.message
				});
			});
	};
};

/**
 * get autocomplete data for postla code and house number
 * @param houseNumber
 * @param postalCode
 */
export const getAutocompleteDataAction = (
	houseNumber?: string,
	postalCode?: string
) => {
	return (dispatch: any) => {
		getAutocompleteData(houseNumber, postalCode)
			.then((response: any) => {
				return dispatch({
					type: SET_AUTOCOMPLETE_DATA,
					payload: response
				});
			})
			.catch(() => {
				dispatch({
					type: SET_AUTOCOMPLETE_DATA_LOADING
				});
			});
	};
};
